import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  PaginationItem,
  PaginationLink,
  Input,
  CardHeader,
  Collapse,
} from "reactstrap"
import { json, useNavigate } from "react-router-dom"
import jwtDecode from "common/TokenJwt/jwt-decode"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import MultipleChoice from "../SoalMultipleChoice/tipeA"
import SoalMultipleChoice from "./TipeSoal/muliple_ca"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer)
import classnames from "classnames"

const sjt = props => {
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [dataPage, setDataPage] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [urutanSkenario, setUrutanSkenario] = useState(0)
  const [skenarioId, setSkenarioId] = useState(0)
  const [petunjuk, setPetunjuk] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [statusTImer, setStatusTimer] = useState(false)
  const customLayout = props.customLayout
  const [data, setData] = useState()
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk

  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const subTest = props.subTest

  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const [selectedOptionsPetunjuk, setSelectedOptionsPetunjuk] = useState([])
  const [error, setError] = useState(false)
  const [totalJawaban, setTotalJawaban] = useState(0)
  const [answeredCounts, setAnsweredCounts] = useState({})
  const [totalAnswered, setTotalAnswered] = useState(0)
  const [totalJawabanByQuestion, setTotalJawabanByQuestion] = useState({})
  const [TotalSemuaJawaban, setTotalSemuaJawaban] = useState(0)

  const navigate = useNavigate()

  const getDataJawaban = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/viewJawaban?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setkondisiSoal(true)
          setPetunjuk(true)
          setSelectedOptions(responseData.data[0])
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
      .catch(error => {
        {
          if (soalPetunjuk.length > 0) {
            setkondisiSoal(true)
            setPetunjuk(true)
          } else {
            setkondisiSoal(false)
            setPetunjuk(false)
          }
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
  }

  // const handleRadioChange = (
  //   id_msoal,
  //   mt_idsubtest,
  //   no_soal,
  //   soal,
  //   id_soal_jawaban,
  //   pilihan,
  //   value,
  //   ju_id_indikator_jawaban,
  //   ju_id_indikator
  // ) => {
  //   const updatedSelectedOptions = [...selectedOptions]
  //   const index = parseInt(no_soal) - 1

  //   for (let i = 0; i < data.length; i++) {
  //     if (i === index) {
  //       // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
  //       if (updatedSelectedOptions[i]) {
  //         updatedSelectedOptions[i] = {
  //           ...updatedSelectedOptions[i],
  //           id_msoal,
  //           mt_idsubtest,
  //           no_soal,
  //           soal,
  //           id_soal_jawaban,
  //           pilihan,
  //           value,
  //           ju_id_indikator_jawaban,
  //           ju_id_indikator
  //         }
  //       } else {
  //         // Jika data belum ada pada indeks yang sesuai, isi data baru
  //         updatedSelectedOptions[i] = {
  //           id_msoal,
  //           mt_idsubtest,
  //           no_soal,
  //           soal,
  //           id_soal_jawaban,
  //           pilihan,
  //           value,
  //           ju_id_indikator_jawaban,
  //           ju_id_indikator
  //         }
  //       }
  //     } else {
  //       // Jika indeks tidak sesuai, tambahkan array kosong
  //       if (!updatedSelectedOptions[i]) {
  //         updatedSelectedOptions[i] = []
  //       }
  //     }
  //   }

  //   setSelectedOptions(updatedSelectedOptions)
  // }

  useEffect(() => {
    if (selectedOptions.length > 0) {
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )
      var formdata = new FormData()
      formdata.append("id_jadwal", DecodeJwt.id_jadwal)
      formdata.append("nip", DecodeJwt.nip)
      formdata.append("id_alattest", idAlattest)
      formdata.append("id_subtest", idSubtest)
      formdata.append("data", JSON.stringify(selectedOptions))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
        requestOptions
      )
        .then(response => response.text())
        .then(result => { })
        .catch(error => {
          Swal.fire({
            title: "Terjadi kesalahan, Silahkan hubungi admin",
            icon: "error",
          })
        })
    } else {
      getDataJawaban()
    }
  }, [selectedOptions])

  const handleSaveJawaban = () => {
    console.log(selectedOptions.length, dataSoal.length)
    if (selectedOptions.length < dataSoal.length) {
      Swal.fire({
        title: "Incomplete!",
        text: "Please make sure to complete all the questions first.",
        icon: "warning",
        confirmButtonText: "OK",
      })
      return null
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit your answers?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing data...",
          text: "Please wait a moment...", // Optional text to provide additional information
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
          showConfirmButton: false,
        })

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        )

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/updateStatus?idSubtest=" +
          idSubtest +
          "&idAlattest=" +
          idAlattest +
          "&nip=" +
          DecodeJwt.nip +
          "&id_jadwal=" +
          DecodeJwt.id_jadwal +
          "&id_asesi=" +
          DecodeJwt.id_jadwal_asesi,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            navigate("/soalListAlatTes")
            Swal.fire({
              title: "Process completed!",
              icon: "success",
              timer: 1000, // Set the timer to 1000 milliseconds (1 second)
              showConfirmButton: false, // Hide the Confirm button
            })
          })
          .catch(error => console.log("error", error))
      }
    })
  }
  const handleSaveJawabanPetunjuk = () => {
    // setSelectedOptions([])
    setkondisiSoal(false)
    setPetunjuk(false)
    setPage(1)
  }

  const handleChecked = id_soal_jawaban => {
    if (data) {
      console.log("id soal jawaban :" + id_soal_jawaban)
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptions[index] &&
          selectedOptions[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  const setIndexBackCase = () => {
    setUrutanSkenario(urutanSkenario - 1)
  }

  const uniqueSkenarioIds = [
    ...new Set(props.data.map(item => item.id_skenario)),
  ]
  //ambil case yang berbeda
  const filteredData = uniqueSkenarioIds.map(skenarioId => {
    return props.data.find(item => item.id_skenario === skenarioId)
  })

  const sortedData = _.sortBy(filteredData, ["skenario.id_mskenario"])

  useEffect(() => {
    const idSkenario = sortedData[urutanSkenario].id_mskenario
    setSkenarioId(idSkenario)
    console.log("urutan :" + urutanSkenario)
    console.log(sortedData)
  }, [sortedData, urutanSkenario])

  const jumlahSkenario = sortedData.length

  const checkListSoal = (skenarioId, index) => {
    console.log(props.data)
    const foundItem = props.data.find(item => item.id_skenario === skenarioId)
    if (foundItem) {
      const index = uniqueSkenarioIds.indexOf(skenarioId)
      setUrutanSkenario(index)
    } else {
      console.log(`Case ID: ${skenarioId} not found.`)
    }

    setPage(index + 1)
  }

  useEffect(() => {
    // console.log(kondisiSoal)
    if (kondisiSoal && soalPetunjuk.length > 0) {
      const filteredData = soalPetunjuk
      setDataPage(filteredData.length)
      setData(soalPetunjuk)
      setPetunjuk(true)
    } else {
      const filteredData = dataSoal
      setDataPage(filteredData.length)
      setData(dataSoal)
      setPetunjuk(false)
    }
  }, [kondisiSoal])

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(Math.ceil(dataPage / itemsPerPage))

  useEffect(() => {
    setTotalPage(Math.ceil(dataPage / itemsPerPage))
  }, [dataPage])

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  // console.log(itemsPerPage, page)
  const isLastPage = page === totalPage

  const filteredDataSoal = dataSoal
  const filteredDataPetunjuk = soalPetunjuk

  useEffect(() => {
    if (statusTImer === true) {
      Swal.fire({
        title: "Processing data...",
        text: "Please wait a moment...", // Optional text to provide additional information
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false,
      })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "jawaban/updateStatus?idSubtest=" +
        idSubtest +
        "&idAlattest=" +
        idAlattest +
        "&nip=" +
        DecodeJwt.nip +
        "&id_jadwal=" +
        DecodeJwt.id_jadwal +
        "&id_asesi=" +
        DecodeJwt.id_jadwal_asesi,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          navigate("/soalListAlatTes")
          Swal.fire({
            title: "Process completed!",
            icon: "success",
            timer: 1000, // Set the timer to 1000 milliseconds (1 second)
            showConfirmButton: false, // Hide the Confirm button
          })
        })
      // .catch(error => console.log("error", error))
    }
  }, [statusTImer])

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      id_soal_jawaban,
      value,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)

    // Update answeredQuestions set
    const updatedAnsweredQuestions = new Set(answeredQuestions)
    updatedAnsweredQuestions.add(id_msoal) // Add the question ID to the set

    // Filter out invalid entries and count the valid answers
    const validAnswers = updatedSelectedOptions.filter(
      item => item.no_soal !== undefined
    )
    setTotalJawaban(validAnswers.length)

    // Update TotalSemuaJawaban
    setTotalSemuaJawaban(validAnswers.length)

    // Log totalJawaban
    console.log("Total Jawaban:", validAnswers.length)
  }

  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  // const handleRadioChangePetunjuk = (
  //   id_msoal,
  //   mt_idsubtest,
  //   no_soal,
  //   soal,
  //   id_soal_jawaban,
  //   pilihan,
  //   value,
  //   ju_id_indikator_jawaban
  // ) => {
  //   const updatedSelectedOptions = [...selectedOptionsPetunjuk]
  //   const index = parseInt(no_soal) - 1

  //   for (let i = 0; i < data.length; i++) {
  //     if (i === index) {
  //       // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
  //       if (updatedSelectedOptions[i]) {
  //         updatedSelectedOptions[i] = {
  //           ...updatedSelectedOptions[i],
  //           id_msoal,
  //           mt_idsubtest,
  //           no_soal,
  //           soal,
  //           id_soal_jawaban,
  //           pilihan,
  //           value,
  //           ju_id_indikator_jawaban
  //         }
  //       } else {
  //         // Jika data belum ada pada indeks yang sesuai, isi data baru
  //         updatedSelectedOptions[i] = {
  //           id_msoal,
  //           mt_idsubtest,
  //           no_soal,
  //           soal,
  //           id_soal_jawaban,
  //           pilihan,
  //           value,
  //           ju_id_indikator_jawaban
  //         }
  //       }
  //     } else {
  //       // Jika indeks tidak sesuai, tambahkan array kosong
  //       if (!updatedSelectedOptions[i]) {
  //         updatedSelectedOptions[i] = []
  //       }
  //     }
  //   }

  //   setSelectedOptionsPetunjuk(updatedSelectedOptions)
  // }

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [data])

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [selectedOptions])

  useEffect(() => {
    // Create an object to keep track of total answers for each question number
    const totalAnswersByQuestion = _.countBy(selectedOptions, "no_soal")

    // Optionally, you can log or set this value to the state if needed
    console.log("Total Jawaban per Nomor Soal:", totalAnswersByQuestion)

    // If you want to set this information in a state for further use
    setTotalJawabanByQuestion(totalAnswersByQuestion)
  }, [selectedOptions])

  useEffect(() => {
    const counts = {}

    selectedOptions.forEach(option => {
      const { no_soal } = option
      if (no_soal !== undefined) {
        // Pastikan no_soal tidak undefined
        if (!counts[no_soal]) {
          counts[no_soal] = 0
        }
        counts[no_soal] += 1
      }
    })

    const total = Object.keys(counts).length

    setAnsweredCounts(counts)
    setTotalSemuaJawaban(total)

    // Log the data using console.table
    const tableData = Object.entries(counts).map(([questionNo, count]) => ({
      Nomor: questionNo,
      TotalJawaban: count,
      TotalSemuaJawaban: total,
    }))

    // console.table(tableData)
  }, [selectedOptions])

  const handleCheckedPetunjuk = id_soal_jawaban => {
    if (data) {
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptionsPetunjuk[index] &&
          selectedOptionsPetunjuk[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  useEffect(() => {
    if (props.idLayout === 6 || props.idLayout === 14) {
      if (selectedOptions.length > 0) {
        // Swal.fire({
        //   title: "Proses data...",
        //   allowOutsideClick: false,
        //   onBeforeOpen: () => {
        //     Swal.showLoading()
        //   },
        //   showConfirmButton: false,
        // })

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        )
        var formdata = new FormData()
        formdata.append("id_jadwal", DecodeJwt.id_jadwal)
        formdata.append("nip", DecodeJwt.nip)
        formdata.append("id_alattest", idAlattest)
        formdata.append("id_subtest", idSubtest)
        formdata.append("data", JSON.stringify(selectedOptions))

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            if (error) {
              // Swal.fire({
              //   title: "skala sudah terpilih cari skala lain",
              //   icon: "error",
              //   timer: 3000,
              //   showConfirmButton: false,
              // })
            } else {
              // Swal.fire({
              //   title: "Proses selesai!",
              //   icon: "success",
              //   timer: 1000,
              //   showConfirmButton: false,
              // })
            }
          })
          .catch(error => {
            console.log("error", error)
            // Menutup SweetAlert jika terjadi kesalahan
            Swal.fire({
              title: "Terjadi kesalahan, Silahkan hubungi admin",
              icon: "error",
            })
          })
      } else {
        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer" + localStorage.getItem("access_token")
        )

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/viewJawaban?" +
          "id_subtest=" +
          idSubtest +
          "&id_alattest=" +
          idAlattest +
          "&id_jadwal=" +
          DecodeJwt.id_jadwal +
          "&nip=" +
          DecodeJwt.nip,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            const responseData = JSON.parse(result)
            if (responseData.meta.code == 200) {
              setSelectedOptions(responseData.data[0])
            }
          })
          .catch(error => {
            setkondisiSoal(true)
            // Swal.fire({
            //   title: "Proses selesai!",
            //   icon: "success",
            //   timer: 1000,
            //   showConfirmButton: false,
            // })
          })
      }
    }
  }, [selectedOptions])

  const [tandaiState, setTandaiState] = useState([])

  const handleTandaiSoal = () => {
    setTandaiState(prevState => {
      const updatedState = [...prevState]
      if (!updatedState.includes(page - 1)) {
        updatedState.push(page - 1)
        Swal.fire({
          title: "Soal Nomor " + page + " Ditandai",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        })
      } else {
        updatedState.splice(updatedState.indexOf(page - 1), 1)
      }
      return updatedState
    })
  }

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  const [openAccordions, setOpenAccordions] = useState(true)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(6)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setSoalState(soalState === 6 ? 8 : 6)
    setPetunjukStatus(petunjukStatus === true ? false : true)
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          <div className="accordion m-3" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !openAccordions,
                  })}
                  type="button"
                  onClick={() => toggleAccordion()}
                  style={{ cursor: "pointer" }}
                >
                  Instruksi
                </button>
              </h2>

              <Collapse isOpen={openAccordions} className="accordion-collapse">
                <div className="accordion-body">
                  <CardBody className="bg-light rounded rounded-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subTest?.instruksi,
                      }}
                    />
                  </CardBody>
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </Row>
      {kondisiSoal === false && (
        <div className="alert alert-primary d-flex align-items-center justify-content-center">
          <p
            className="fs-5 fw-medium ms-3 text-center"
            style={{ lineHeight: "1" }}
          >
            <MemoizedCountdownTimer
              durationInMinutes={props.waktu}
              setStatusTimer={setStatusTimer}
              statusTimer={props.statusTimer}
            />
          </p>
        </div>
      )}
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={4}>
          {filteredDataPetunjuk.length > 0 && kondisiSoal
            ? filteredDataPetunjuk
              .slice(startIndex, endIndex)
              .filter(item => item.soal !== undefined)
              .map((item, index) => (
                <div key={index}>
                  <Card className="bg-transparent border border-primary shadow shadow-lg shadow-lg d-flex flex-column mh-25 full-height">
                    <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                      <label className="mt-1"> Skenario</label>
                    </CardHeader>
                    <CardBody style={{ flex: 1, overflow: "auto" }}>
                      <p className="card-title">
                        1. {item.skenario.judul_skenario}
                      </p>
                      <div className="mt-3">
                        <hr
                          size="5"
                          width="100%"
                          className="border-primary border-2 border border-opacity-10"
                        />
                      </div>
                      <p className="card-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.skenario.isi_skenario,
                          }}
                        ></div>
                      </p>
                    </CardBody>
                  </Card>
                </div>
              ))
            : filteredDataSoal
              .slice(startIndex, endIndex)
              .filter(item => item.soal !== undefined)
              .map((item, index) => (
                <div key={index}>
                  <Card className="bg-transparent border border-primary shadow shadow-lg shadow-lg d-flex flex-column mh-25 full-height">
                    <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                      <label className="mt-1"> Skenario</label>
                    </CardHeader>
                    <CardBody style={{ flex: 1, overflow: "auto" }}>
                      <p className="card-title">
                        1. {item.skenario.judul_skenario}
                      </p>
                      <div className="mt-3">
                        <hr
                          size="5"
                          width="100%"
                          className="border-primary border-2 border border-opacity-10"
                        />
                      </div>
                      <p className="card-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.skenario.isi_skenario,
                          }}
                        ></div>
                      </p>
                    </CardBody>
                  </Card>
                </div>
              ))}
        </Col>
        <Col xl={soalState}>
          {Array.isArray(props.data) ? (
            props.idLayout === 1 ||
              props.idLayout === 2 ||
              props.idLayout === 3 ||
              props.idLayout === 4 ? (
              <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
                <CardBody style={{ flex: 1, overflow: "auto" }}>
                  <Col xl={12}>
                    {filteredDataPetunjuk.length > 0 && kondisiSoal
                      ? filteredDataPetunjuk
                        .slice(startIndex, endIndex)
                        .filter(item => item.soal !== undefined)
                        .map((item, index) => (
                          <div key={index}>
                            <Card
                              className="border border-primary d-flex flex-column mh-25 full-height"
                              style={
                                customLayout != null
                                  ? customLayoutCardSoal
                                  : null
                              }
                            >
                              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                                <label className="mt-1"> Soal Petunjuk</label>
                              </CardHeader>
                              <CardBody className="text-left">
                                <p className="card-text mb-3 fw-semibold fs-5">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutSoal
                                        : null
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: item.soal,
                                    }}
                                  />
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col xl="12">
                                    <Row>
                                      {item.pilihan_jawaban.map(
                                        (item2, index2) => (
                                          <Col
                                            xl={12}
                                            md={12}
                                            sm={12}
                                            key={index2}
                                          >
                                            <label
                                              className="card-radio-label"
                                              style={
                                                customLayout != null
                                                  ? customLayoutCardJawaban
                                                  : null
                                              }
                                            >
                                              <input
                                                type="radio"
                                                name={`pilihan_${index}`}
                                                onClick={() =>
                                                  handleRadioChangePetunjuk(
                                                    item.id_msoal,
                                                    item.mt_idsubtest,
                                                    item.no_soal,
                                                    item.soal,
                                                    item2.id_soal_jawaban,
                                                    item2.pilihan,
                                                    item2.value,
                                                    item2.id_indikator
                                                  )
                                                }
                                                className="card-radio-input"
                                                checked={handleCheckedPetunjuk(
                                                  item2.id_soal_jawaban
                                                )}
                                              />

                                              <div className="card-radio border border-primary d-flex">
                                                <div
                                                  style={
                                                    customLayout != null
                                                      ? customLayoutJawaban
                                                      : {
                                                        width: "100%",
                                                        wordWrap:
                                                          "break-word",
                                                        whiteSpace:
                                                          "normal",
                                                      }
                                                  }
                                                  className="mt-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item2.pilihan,
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        ))
                      : filteredDataSoal
                        .slice(startIndex, endIndex)
                        .filter(item => item.soal !== undefined)
                        .map((item, index) => (
                          <div key={index}>
                            <Card
                              className="border border-primary d-flex flex-column mh-25 full-height"
                              style={
                                customLayout != null
                                  ? customLayoutCardSoal
                                  : null
                              }
                            >
                              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                                <label className="mt-1"> Soal</label>

                                <button
                                  type="button"
                                  onClick={() => {
                                    expand()
                                  }}
                                  className="btn noti-icon text-white float-end"
                                >
                                  <i
                                    className="bx bx-fullscreen"
                                    style={{ color: "white" }}
                                  />
                                </button>
                                <Button
                                  className="float-end btn btn-danger"
                                  color={
                                    tandaiState.includes(page - 1)
                                      ? "danger"
                                      : "primary"
                                  }
                                  onClick={handleTandaiSoal}
                                >
                                  {tandaiState.includes(page - 1)
                                    ? "Hapus Penandaan"
                                    : "Tandai"}
                                </Button>
                              </CardHeader>
                              <CardBody className="text-left">
                                <p className="card-text mb-3 fw-semibold fs-5">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutSoal
                                        : null
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: item.soal,
                                    }}
                                  />
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col xl="12">
                                    <Row>
                                      {item.pilihan_jawaban.map(
                                        (item2, index2) => (
                                          <Col
                                            xl={12}
                                            md={12}
                                            sm={12}
                                            key={index2}
                                          >
                                            <label className="card-radio-label">
                                              <input
                                                type="radio"
                                                name={`pilihan_${index}`}
                                                onClick={() =>
                                                  handleRadioChange(
                                                    item.id_msoal,
                                                    item.mt_idsubtest,
                                                    item.no_soal,
                                                    item2.id_soal_jawaban,
                                                    item2.value,
                                                    item2.id_indikator,
                                                    item.id_indikator
                                                  )
                                                }
                                                className="card-radio-input"
                                                checked={handleChecked(
                                                  item2.id_soal_jawaban
                                                )}
                                              />

                                              <div
                                                className="card-radio border border-primary d-flex"
                                                style={
                                                  customLayout != null
                                                    ? customLayoutCardJawaban
                                                    : null
                                                }
                                              >
                                                <div
                                                  style={
                                                    customLayout != null
                                                      ? customLayoutJawaban
                                                      : {
                                                        width: "100%",
                                                        wordWrap:
                                                          "break-word",
                                                        whiteSpace:
                                                          "normal",
                                                      }
                                                  }
                                                  className="mt-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item2.pilihan,
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                                <Row></Row>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                  </Col>
                </CardBody>
              </Card>
            ) : (
              <>
                {soalPetunjuk.length > 0 && kondisiSoal
                  ? soalPetunjuk
                    .slice(startIndex, endIndex)
                    .filter(item => item.soal !== undefined)
                    .map((item, index) => (
                      <Card
                        className="border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height"
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                        key={index}
                      >
                        <CardBody style={{ flex: 1, overflow: "auto" }}>
                          <div className="d-flex">
                            <p
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              className="card-title"
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            ></p>
                          </div>
                          <hr
                            size="5"
                            width="100%"
                            className="border-primary border-2 border border-opacity-10"
                          />
                          <table className="table table-striped text-center">
                            <thead>
                              <tr>
                                <th></th>
                                {JSON.parse(item.skala).map(
                                  (item3, index3) => (
                                    <th key={index3}>{item3}</th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {item.pilihan_jawaban.map((item2, index2) => (
                                <tr key={index2}>
                                  <td className="text-start">
                                    <p
                                      style={
                                        customLayout != null
                                          ? customLayoutSoal
                                          : null
                                      }
                                      className="fw-normal"
                                      dangerouslySetInnerHTML={{
                                        __html: item2.pilihan,
                                      }}
                                    ></p>
                                  </td>
                                  {JSON.parse(item2.value).map(
                                    (item4, index4) => (
                                      <td key={index4}>
                                        <Input
                                          type="radio"
                                          className="form-check-input border border-2 border-primary"
                                          name={`pilihan_${index}${index2}`}
                                          onClick={() =>
                                            handleRadioChangePetunjuk(
                                              item.id_msoal,
                                              item.mt_idsubtest,
                                              item.no_soal,
                                              item.soal,
                                              item2.id_soal_jawaban,
                                              item2.pilihan,
                                              item4,
                                              JSON.parse(item.skala),
                                              index4,
                                              item2.id_indikator
                                            )
                                          }
                                          checked={selectedOptionsPetunjuk.some(
                                            option =>
                                              option.id_msoal ===
                                              item.id_msoal &&
                                              option.id_soal_jawaban ===
                                              item2.id_soal_jawaban &&
                                              option.index === index4
                                          )}
                                        />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    ))
                  : dataSoal
                    .slice(startIndex, endIndex)
                    .filter(item => item.soal !== undefined)
                    .map((item, index) => (
                      <Card
                        className="border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height"
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                        key={index}
                      >
                        <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                          <label className="mt-1"> Soal</label>
                          <button
                            type="button"
                            onClick={() => {
                              expand()
                            }}
                            className="btn noti-icon text-white float-end"
                          >
                            <i
                              className="bx bx-fullscreen"
                              style={{ color: "white" }}
                            />
                          </button>
                          <Button
                            className="float-end btn btn-danger"
                            color={
                              tandaiState.includes(page - 1)
                                ? "danger"
                                : "primary"
                            }
                            onClick={handleTandaiSoal}
                          >
                            {tandaiState.includes(page - 1)
                              ? "Hapus Penandaan"
                              : "Tandai"}
                          </Button>
                        </CardHeader>
                        <CardBody style={{ flex: 1, overflow: "auto" }}>
                          <div className="d-flex">
                            <p
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              className="card-title"
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            ></p>
                          </div>
                          <hr
                            size="5"
                            width="100%"
                            className="border-primary border-2 border border-opacity-10"
                          />
                          <table className="table table-striped text-center">
                            <thead>
                              <tr>
                                <th></th>
                                {JSON.parse(item.skala).map(
                                  (item3, index3) => (
                                    <th key={index3}>{item3}</th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {item.pilihan_jawaban.map((item2, index2) => (
                                <tr key={index2}>
                                  <td className="text-start">
                                    <p
                                      className="fw-normal"
                                      style={
                                        customLayout != null
                                          ? customLayoutJawaban
                                          : null
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: item2.pilihan,
                                      }}
                                    ></p>
                                  </td>
                                  {item2.value &&
                                    JSON.parse(item2.value).map(
                                      (item4, index4) => (
                                        <td key={index4}>
                                          <Input
                                            type="radio"
                                            className="form-check-input border border-2 border-primary"
                                            name={`pilihan_${index}${index2}`}
                                            onClick={() =>
                                              handleRadioChange(
                                                item.id_msoal,
                                                item.mt_idsubtest,
                                                item.no_soal,
                                                item2.id_soal_jawaban,
                                                item4,
                                                JSON.parse(item.skala),
                                                index4,
                                                item2.id_indikator
                                              )
                                            }
                                            checked={selectedOptions.some(
                                              option =>
                                                option.id_msoal ===
                                                item.id_msoal &&
                                                option.id_soal_jawaban ===
                                                item2.id_soal_jawaban &&
                                                option.index === index4
                                            )}
                                          />
                                        </td>
                                      )
                                    )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    ))}
                <Row>
                  <Col lg="12">
                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                      <PaginationItem
                        disabled={page === 1}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          previous
                          onClick={e => {
                            e.preventDefault()
                            setPage(page - 1)
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              transform: "rotate(180deg)",
                            }}
                          >
                            &#10140;
                          </span>{" "}
                          Sebelumnya
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        disabled={page === totalPage}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          next
                          onClick={e => {
                            e.preventDefault()
                            setPage(page + 1)
                          }}
                        >
                          Selanjutnya &#10140;
                        </PaginationLink>
                      </PaginationItem>
                    </ul>
                  </Col>
                </Row>
              </>
            )
          ) : (
            <p>props.data is not an array.</p>
          )}
        </Col>
        {petunjukStatus && (
          <Col xl={2}>
            <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                Nomer Soal
              </CardHeader>
              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <Row>
                  {petunjuk
                    ? // Jika petunjuk adalah true, gunakan dataSoal
                    soalPetunjuk.map((item, index) => (
                      <Col xl={2} key={index}>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            onClick={() =>
                              checkListSoal(item.id_skenario, index)
                            }
                            className="card-radio-input"
                            checked={
                              props.idLayout === 1 ||
                                props.idLayout === 2 ||
                                props.idLayout === 3 ||
                                props.idLayout === 4
                                ? selectedOptionsPetunjuk.some(
                                  option =>
                                    option.id_msoal === item.id_msoal
                                ) &&
                                !tandaiState.includes(index) &&
                                page - 1 !== index
                                : props.idLayout === 6
                                  ? selectedOptionsPetunjuk.some(
                                    option =>
                                      option.id_msoal === item.id_msoal
                                  )
                                  : ""
                            }
                          />
                          <div
                            className={`card-radio ${tandaiState.includes(index)
                              ? "bg-danger"
                              : page - 1 === index
                                ? "bg-secondary text-white"
                                : ""
                              }`}
                          >
                            {index + 1}
                          </div>
                        </label>
                      </Col>
                    ))
                    : // Jika petunjuk adalah false, gunakan soalPetunjuk
                    dataSoal.map((item, index) => (
                      <Col xl={2} key={index}>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            onClick={() =>
                              checkListSoal(item.id_skenario, index)
                            }
                            className="card-radio-input"
                            checked={
                              props.idLayout === 1 ||
                                props.idLayout === 2 ||
                                props.idLayout === 3 ||
                                props.idLayout === 4
                                ? selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal
                                ) &&
                                !tandaiState.includes(index) &&
                                page - 1 !== index
                                : props.idLayout === 6
                                  ? selectedOptions.some(
                                    option =>
                                      option.id_msoal === item.id_msoal
                                  )
                                  : ""
                            }
                          />
                          <div
                            className={`card-radio ${tandaiState.includes(index)
                              ? "bg-danger"
                              : page - 1 === index
                                ? "bg-secondary text-white"
                                : ""
                              }`}
                          >
                            {index + 1}
                          </div>
                        </label>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col xl={12}>
          <div className="text-end my-2">
            {kondisiSoal && soalPetunjuk.length > 0 && page === totalPage ? (
              <button
                className="btn-primary btn rounded rounded-3"
                onClick={() => handleSaveJawabanPetunjuk()}
              >
                Mulai Kerjakan
              </button>
            ) : (
              page === totalPage && (
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => handleSaveJawaban()}
                >
                  Simpan Jawaban
                </button>
              )
            )}
          </div>
        </Col>
      </Row>
      {((isLastPage && (props.idLayout === 6 || props.idLayout === 14)) ||
        statusTImer === true) && (
          <Row style={{ paddingTop: "50px" }}>
            <Col>
              <SaveJawaban
                idAlattest={idAlattest}
                idSubtest={idSubtest}
                id_jadwal={DecodeJwt.id_jadwal}
                nip={DecodeJwt.nip}
                id_asesi={DecodeJwt.id_jadwal_asesi}
                kondisiSoal={kondisiSoal}
                setKondisiSoal={setkondisiSoal}
                setPage={setPage}
                statusTImer={statusTImer}
                dataSoal={dataSoal}
                soalPetunjuk={soalPetunjuk}
                selectedOptions={selectedOptions}
                totalJawaban={totalJawaban}
                TotalSemuaJawaban={TotalSemuaJawaban}
              />
            </Col>
          </Row>
        )}
    </div>
  )
}

export default sjt

import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"

const matrix_ca = ({
  idLayout,
  data,
  skenario,
  idAlattest,
  idSubtest,
  caseId,
  setUrutanCase,
  urutanCase,
  jumlahCase,
  setOptions,
  statusTImer,
  customLayout,
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [error, setError] = useState(false)

  const setIndexNextCase = () => {
    setUrutanCase(urutanCase + 1)
  }

  const setIndexBackCase = () => {
    setUrutanCase(urutanCase - 1)
  }

  //pagination
  const itemsPerPage = 2 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const filteredData = data.filter(item => item.case_id === caseId)
  const totalPage = Math.ceil(filteredData.length / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const sortedData = _.sortBy(selectedOptions, ["no_soal"])

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    id_aspekat,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      id_soal_jawaban,
      value,
      id_aspekat,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          console.log(
            "Skala sudah di Inputkan, pilih skala lain atau ubah skala"
          )
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          console.log(
            "Skala sudah di Inputkan, pilih skala lain atau ubah skala1"
          )
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }
  const handleSaveJawaban = () => { }

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          <div className="alert alert-success w-100 d-flex align-items-center">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Pastikan anda mengisi semua soal
            </p>
          </div>
        )}
      </Row>

      <Row>
        {filteredData.slice(startIndex, endIndex).map((item, outerIndex) => (
          <Col xl={12} key={outerIndex}>
            <Card className="shadow shadow-lg d-flex flex-column mh-25">
              <CardHeader className="d-flex  border-bottom border-4 bg-white">
                <p
                  style={customLayout != null ? customLayoutSoal : null}
                  className="card-title"
                  dangerouslySetInnerHTML={{
                    __html: item.soal,
                  }}
                ></p>
              </CardHeader>
              <CardBody>
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center fw-bold">
                      <th></th>
                      {JSON.parse(item.skala).map((item2, index2) => (
                        <th key={index2}>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item2,
                            }}
                          ></div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.pilihan_jawaban.map((item3, innerIndex) => (
                      <tr className="text-center fw-bold" key={innerIndex}>
                        <td>
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item3.pilihan,
                            }}
                          ></div>
                        </td>
                        {JSON.parse(item3.value).map((item4, index4) => (
                          <td key={index4}>
                            <input
                              type="radio"
                              className="form-check-input border border-2 border-primary"
                              name={`pilihan_${outerIndex}${innerIndex}`}
                              onClick={() =>
                                handleRadioChange(
                                  item.id_msoal,
                                  item.mt_idsubtest,
                                  item.no_soal,
                                  item3.id_soal_jawaban,
                                  item4,
                                  item3.id_aspekat,
                                  JSON.parse(item.skala),
                                  index4,
                                  item3.id_indikator
                                )
                              }
                              checked={selectedOptions.some(
                                option =>
                                  option.id_msoal === item.id_msoal &&
                                  option.id_soal_jawaban ===
                                  item3.id_soal_jawaban &&
                                  option.index === index4
                              )}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        ))}
        {/* <Row>
          <Col lg="12">
            <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
              <PaginationItem disabled={page === 1}>
                <PaginationLink
                  previous
                  href="#"
                  onClick={() => setPage(page - 1)}
                />
              </PaginationItem>

              {Array.from({ length: totalPage }).map((_, i) => (
                <PaginationItem active={i + 1 === page} key={i}>
                  <PaginationLink onClick={() => setPage(i + 1)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={page === totalPage}>
                <PaginationLink
                  next
                  href="#"
                  onClick={() => setPage(page + 1)}
                />
              </PaginationItem>
            </ul>
          </Col>
        </Row> */}
        {/* <Row>
          <Col xl={6}>
            <div className="text-start">
              {isLastPage && urutanCase > 0 && (
                <button
                  className="btn btn-primary"
                  onClick={() => setIndexBackCase()}
                >
                  <i className="bx bx-left-arrow-alt"></i>
                </button>
              )}
            </div>
          </Col>
          <Col xl={6}>
            <div className="text-end">
              {isLastPage && urutanCase + 1 < jumlahCase ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setIndexNextCase()}
                >
                  <i className="bx bx-right-arrow-alt"></i>
                </button>
              ) : (
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => handleSaveJawaban()}
                >
                  Simpan Jawaban
                </button>
              )}
            </div>
          </Col>
        </Row> */}
      </Row>
    </div>
  )
}

export default matrix_ca

import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"

const multiple_ca = ({
  idAlattest,
  idSubtest,
  jenisSoal,
  caseId,
  urutanCase,
  setUrutanCase,
  jumlahCase,
  setOptions,
  soalPetunjuk,
  dataSoal,
  setPetunjuk,
  customLayout,
  activeIndex,
  selectedOptionsPetunjuk,
  setSelectedOptionsPetunjuk,
  tandaiState,
  handleTandaiSoal,
  setActiveIndex,
  updateCaseDetails,
  setTotalJawaban,
  statusTImer
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [dataPage, setDataPage] = useState()
  const [data, setData] = useState()
  // const [statusTImer, setStatusTimer] = useState(false)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    ju_id_indikator_jawaban,
    ju_id_indikator
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const index = parseInt(no_soal) - 1

    for (let i = 0; i < data.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            mt_idsubtest,
            no_soal,
            id_soal_jawaban,
            value,
            ju_id_indikator_jawaban,
            ju_id_indikator,
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            mt_idsubtest,
            no_soal,
            id_soal_jawaban,
            value,
            ju_id_indikator_jawaban,
            ju_id_indikator,
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }

  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    ju_id_indikator_jawaban,
    ju_id_indikator
  ) => {
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]
    const index = parseInt(no_soal) - 1

    for (let i = 0; i < data.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            mt_idsubtest,
            no_soal,
            soal,
            id_soal_jawaban,
            pilihan,
            value,
            ju_id_indikator_jawaban,
            ju_id_indikator,
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            mt_idsubtest,
            no_soal,
            soal,
            id_soal_jawaban,
            pilihan,
            value,
            ju_id_indikator_jawaban,
            ju_id_indikator,
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  const getDataJawaban = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/viewJawaban?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setkondisiSoal(false)
          setPetunjuk(false)
          setSelectedOptions(responseData.data[0])
          setOptions(requestOptions.data[0])
          setTotalJawaban(responseData.data[0].filter(item => Array.isArray(item) ? item.length > 0 : true).length)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
      .catch(error => {
        {
          if (soalPetunjuk.length > 0) {
            setkondisiSoal(true)
            setPetunjuk(true)
          } else {
            setkondisiSoal(false)
            setPetunjuk(false)
          }
          Swal.fire({
            title: "Proses selesai!",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          })
        }
      })
  }

  useEffect(() => {
    if (selectedOptions.length > 0) {
      setTotalJawaban(selectedOptions.filter(item => Array.isArray(item) ? item.length > 0 : true).length)
      setOptions(selectedOptions)
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )
      var formdata = new FormData()
      formdata.append("id_jadwal", DecodeJwt.id_jadwal)
      formdata.append("nip", DecodeJwt.nip)
      formdata.append("id_alattest", idAlattest)
      formdata.append("id_subtest", idSubtest)
      formdata.append("data", JSON.stringify(selectedOptions))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
        requestOptions
      )
        .then(response => response.text())
        .then(result => { })
        .catch(() => {
          Swal.fire({
            title: 'Error',
            text: 'Terjadi kesalahan saat proses simpan jawaban',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate('/dashboard');
          });
        })
    } else {
      getDataJawaban()
    }

  }, [selectedOptions])

  const handleSaveJawaban = () => {
    if (selectedOptions.filter(item => Array.isArray(item) ? item.length > 0 : true).length < dataSoal.length) {
      Swal.fire({
        title: "Incomplete!",
        text: "Please make sure to complete all the questions first.",
        icon: "warning",
        confirmButtonText: "OK",
      })
      return null
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit your answers?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing data...",
          text: "Please wait a moment...", // Optional text to provide additional information
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
          showConfirmButton: false,
        })

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        )

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/updateStatus?idSubtest=" +
          idSubtest +
          "&idAlattest=" +
          idAlattest +
          "&nip=" +
          DecodeJwt.nip +
          "&id_jadwal=" +
          DecodeJwt.id_jadwal +
          "&id_asesi=" +
          DecodeJwt.id_jadwal_asesi,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            navigate("/soalListAlatTes")
            Swal.fire({
              title: "Process completed!",
              icon: "success",
              timer: 1000, // Set the timer to 1000 milliseconds (1 second)
              showConfirmButton: false, // Hide the Confirm button
            })
          })
          .catch(error => console.log("error", error))
      }
    })
  }

  const handleSaveJawabanPetunjuk = () => {
    // setSelectedOptions([])
    setkondisiSoal(false)
    setPetunjuk(false)
    setPage(1)
    setActiveIndex(0)
    // setIndexSoal(1)
  }
  const handleChecked = id_soal_jawaban => {
    if (data) {
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptions[index] &&
          selectedOptions[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  const handleCheckedPetunjuk = id_soal_jawaban => {
    if (data) {
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptionsPetunjuk[index] &&
          selectedOptionsPetunjuk[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  const setIndexNextCase = () => {
    setUrutanCase(urutanCase + 1)
  }

  const setIndexBackCase = () => {
    setUrutanCase(urutanCase - 1)
  }

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      const filteredData = soalPetunjuk
      setDataPage(filteredData.length)
      setData(soalPetunjuk)
      setPetunjuk(true)

      // Console log soal yang ditampilkan dan ID-nya
      filteredData.forEach(item => {
        console.log("Soal Petunjuk:", item.soal, "ID:", item.id_msoal)
      })
    } else {
      const filteredData = dataSoal
      setDataPage(filteredData.length)
      setData(dataSoal)
      setPetunjuk(false)

      // Console log soal yang ditampilkan dan ID-nya
      filteredData.forEach(item => {
        console.log("Soal Biasa:", item.soal, "ID:", item.id_msoal)
      })
    }
  }, [kondisiSoal])

  //pagination
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(Math.ceil(dataPage / itemsPerPage))

  useEffect(() => {
    setTotalPage(Math.ceil(dataPage / itemsPerPage))
  }, [dataPage])

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  // console.log(itemsPerPage, page)
  const isLastPage = page === totalPage

  const filteredData = dataSoal
  const filteredDataPetunjuk = soalPetunjuk

  useEffect(() => {
    if (statusTImer === true) {
      Swal.fire({
        title: "Processing data...",
        text: "Please wait a moment...", // Optional text to provide additional information
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false,
      })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "jawaban/updateStatus?idSubtest=" +
        idSubtest +
        "&idAlattest=" +
        idAlattest +
        "&nip=" +
        DecodeJwt.nip +
        "&id_jadwal=" +
        DecodeJwt.id_jadwal +
        "&id_asesi=" +
        DecodeJwt.id_jadwal_asesi,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          navigate("/soalListAlatTes")
          Swal.fire({
            title: "Process completed!",
            icon: "success",
            timer: 1000, // Set the timer to 1000 milliseconds (1 second)
            showConfirmButton: false, // Hide the Confirm button
          })
        })
        .catch(error => console.log("error", error))
    }
  }, [statusTImer])

  // useEffect untuk mengambil data case_name dan caseContent
  useEffect(() => {
    if (data && data.length > 0 && activeIndex < data.length) {
      const { case_name, case: caseContent } = data[activeIndex].case
      updateCaseDetails(case_name, caseContent)
    }
  }, [data, activeIndex])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  // useEffect untuk index page saat ini
  useEffect(() => {
    // console.log(page)
  }, [urutanCase, page])

  useEffect(() => {
    setPage(activeIndex + 1)
    // console.log("INDEX HALAMAN SAAT INI: ", activeIndex)
  }, [activeIndex])

  const nextPage = page => {
    setPage(page + 1)
    setActiveIndex(page)
  }

  const prevPage = page => {
    setPage(page - 1)
    setActiveIndex(page - 2)
  }

  return (
    <div className="m-0 p-0">
      {/* <div>
        <hr size="5" width="100%" className="bg-light" />
      </div> */}
      {/* card1 */}
      <div className="d-flex justify-content-between">
        {/* card 2 */}
        <Card style={{ flex: 3, margin: "0px", padding: "0px" }}>
          <CardHeader>
            <Row>
              <Col xl={15}>
                {filteredDataPetunjuk.length > 0 && kondisiSoal
                  ? filteredDataPetunjuk
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <div key={index}>
                        <Card
                          className="border border-primary d-flex flex-column "
                          style={
                            customLayout != null ? customLayoutCardSoal : null
                          }
                        >
                          <CardBody>
                            <p className="card-text ">
                              <div
                                style={
                                  customLayout != null
                                    ? customLayoutSoal
                                    : null
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.soal,
                                }}
                              />
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Col xl="12">
                                <Row>
                                  {item.pilihan_jawaban.map(
                                    (item2, index2) => (
                                      <Col
                                        xl={12}
                                        md={12}
                                        sm={12}
                                        key={index2}
                                      >
                                        <label
                                          className="card-radio-label"
                                          style={
                                            customLayout != null
                                              ? customLayoutCardJawaban
                                              : null
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name={`pilihan_${index}`}
                                            onClick={() =>
                                              handleRadioChangePetunjuk(
                                                item.id_msoal,
                                                item.mt_idsubtest,
                                                item.no_soal,
                                                item.soal,
                                                item2.id_soal_jawaban,
                                                item2.pilihan,
                                                item2.value,
                                                item2.id_indikator,
                                                item.id_indikator
                                              )
                                            }
                                            className="card-radio-input"
                                            checked={handleCheckedPetunjuk(
                                              item2.id_soal_jawaban
                                            )}
                                          />

                                          <div className="card-radio border border-primary d-flex">
                                            <div
                                              style={
                                                customLayout != null
                                                  ? customLayoutJawaban
                                                  : { width: "100%", wordWrap: "break-word", whiteSpace: "normal" }
                                              }
                                              className="mt-2"
                                              dangerouslySetInnerHTML={{
                                                __html: item2.pilihan,
                                              }}
                                            ></div>
                                          </div>
                                        </label>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    ))
                  : filteredData
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <div key={index}>
                        <Card
                          className="border border-primary d-flex flex-column "
                          style={
                            customLayout != null ? customLayoutCardSoal : null
                          }
                        >
                          <CardBody>
                            <p className="card-text ">
                              <div
                                style={
                                  customLayout != null
                                    ? customLayoutSoal
                                    : null
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.soal,
                                }}
                              />
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Col xl="12">
                                <Row>
                                  {item.pilihan_jawaban.map(
                                    (item2, index2) => (
                                      <Col
                                        xl={12}
                                        md={12}
                                        sm={12}
                                        key={index2}
                                      >
                                        <label className="card-radio-label">
                                          <input
                                            type="radio"
                                            name={`pilihan_${index}`}
                                            onClick={() =>
                                              handleRadioChange(
                                                item.id_msoal,
                                                item.mt_idsubtest,
                                                item.no_soal,
                                                item2.id_soal_jawaban,
                                                item2.value,
                                                item2.id_indikator,
                                                item.id_indikator
                                              )
                                            }
                                            className="card-radio-input"
                                            checked={handleChecked(
                                              item2.id_soal_jawaban
                                            )}
                                          />

                                          <div
                                            className="card-radio border border-primary d-flex"
                                            style={
                                              customLayout != null
                                                ? customLayoutCardJawaban
                                                : null
                                            }
                                          >
                                            <div
                                              style={
                                                customLayout != null
                                                  ? customLayoutJawaban
                                                  : { width: "100%", wordWrap: "break-word", whiteSpace: "normal" }
                                              }
                                              className="mt-2"
                                              dangerouslySetInnerHTML={{
                                                __html: item2.pilihan,
                                              }}
                                            ></div>
                                          </div>
                                        </label>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    ))}

                <Row>
                  <Col lg="12">
                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                      <PaginationItem
                        disabled={page === 1}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          previous
                          onClick={e => {
                            e.preventDefault()
                            prevPage(page)
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              transform: "rotate(180deg)",
                            }}
                          >
                            &#10140;
                          </span>{" "}
                          Sebelumnya
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        disabled={page === totalPage}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          next
                          onClick={e => {
                            e.preventDefault()
                            nextPage(page)
                          }}
                        >
                          Selanjutnya &#10140;
                        </PaginationLink>
                      </PaginationItem>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <div className="text-start"></div>
                  </Col>
                  <Col xl={6}>
                    <div className="text-end">
                      {kondisiSoal &&
                        soalPetunjuk.length > 0 &&
                        page === totalPage ? (
                        <button
                          className="btn-primary btn rounded rounded-3"
                          onClick={() => handleSaveJawabanPetunjuk()}
                        >
                          Mulai Kerjakan
                        </button>
                      ) : (
                        page === totalPage && (
                          <button
                            className="btn-primary btn rounded rounded-3"
                            onClick={() => handleSaveJawaban()}
                          >
                            Simpan Jawaban
                          </button>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </CardHeader>
        </Card>
      </div>
    </div>
  )
}

export default multiple_ca

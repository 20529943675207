import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  PaginationItem,
  PaginationLink,
  Input,
  CardHeader,
  Collapse
} from "reactstrap"
import { json, useNavigate } from "react-router-dom"
import jwtDecode from "common/TokenJwt/jwt-decode"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import MultipleChoice from "../SoalMultipleChoice/tipeA"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);
import classnames from "classnames"

const sjt = props => {
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)
  const [dataPage, setDataPage] = useState()
  const totalPage = Math.ceil(dataPage / itemsPerPage)
  const customLayout = props.customLayout
  const instruksi = props.instruksi
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage
  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const subTest = props.subTest

  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionsPetunjuk, setSelectedOptionsPetunjuk] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      id_soal_jawaban,
      value,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }
  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  // useEffect(() => {
  //   if (props.idLayout === 6 || props.idLayout === 14) {
  //     if (selectedOptions.length > 0) {
  //       Swal.fire({
  //         title: "Proses data...",
  //         allowOutsideClick: false,
  //         onBeforeOpen: () => {
  //           Swal.showLoading()
  //         },
  //         showConfirmButton: false,
  //       })

  //       var myHeaders = new Headers()
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer " + localStorage.getItem("access_token")
  //       )
  //       var formdata = new FormData()
  //       formdata.append("id_jadwal", DecodeJwt.id_jadwal)
  //       formdata.append("nip", DecodeJwt.nip)
  //       formdata.append("id_alattest", idAlattest)
  //       formdata.append("id_subtest", idSubtest)
  //       formdata.append("data", JSON.stringify(selectedOptions))

  //       var requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: formdata,
  //         redirect: "follow",
  //       }

  //       fetch(
  //         process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
  //         requestOptions
  //       )
  //         .then(response => response.text())
  //         .then(result => {
  //           if (error) {
  //             Swal.fire({
  //               title: "skala sudah terpilih cari skala lain",
  //               icon: "error",
  //               timer: 3000,
  //               showConfirmButton: false,
  //             })
  //           } else {
  //             Swal.fire({
  //               title: "Proses selesai!",
  //               icon: "success",
  //               timer: 1000,
  //               showConfirmButton: false,
  //             })
  //           }
  //         })
  //         .catch(error => {
  //           console.log("error", error)
  //           // Menutup SweetAlert jika terjadi kesalahan
  //           Swal.fire({
  //             title: "Terjadi kesalahan",
  //             icon: "error",
  //           })
  //         })
  //     } else {
  //       var myHeaders = new Headers()
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer" + localStorage.getItem("access_token")
  //       )

  //       var requestOptions = {
  //         method: "GET",
  //         headers: myHeaders,
  //         redirect: "follow",
  //       }

  //       fetch(
  //         process.env.REACT_APP_BACKEND_URL_VERSION +
  //         "jawaban/viewJawaban?" +
  //         "id_subtest=" +
  //         idSubtest +
  //         "&id_alattest=" +
  //         idAlattest +
  //         "&id_jadwal=" +
  //         DecodeJwt.id_jadwal +
  //         "&nip=" +
  //         DecodeJwt.nip,
  //         requestOptions
  //       )
  //         .then(response => response.text())
  //         .then(result => {
  //           const responseData = JSON.parse(result)
  //           if (responseData.meta.code == 200) {
  //             setSelectedOptions(responseData.data[0])
  //           }
  //         })
  //         .catch(error => console.log("error", error))
  //     }
  //   }
  // }, [selectedOptions])

  // const handleSaveJawaban = () => {
  //   Swal.fire({
  //     title: "Proses data...",
  //     text: "Mohon tunggu sebentar...", // Optional text to provide additional information
  //     allowOutsideClick: false,
  //     onBeforeOpen: () => {
  //       Swal.showLoading()
  //     },
  //     showConfirmButton: false,
  //   })

  //   var myHeaders = new Headers()
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("access_token")
  //   )

  //   var requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   }

  //   fetch(
  //     process.env.REACT_APP_BACKEND_URL_VERSION +
  //       "jawaban/updateStatus?idSubtest=" +
  //       idSubtest +
  //       "&idAlattest=" +
  //       idAlattest +
  //       "&nip=" +
  //       DecodeJwt.nip +
  //       "&id_jadwal=" +
  //       DecodeJwt.id_jadwal,
  //     requestOptions
  //   )
  //     .then(response => response.text())
  //     .then(result => {
  //       navigate("/soalListAlatTes")
  //       Swal.fire({
  //         title: "Proses selesai!",
  //         icon: "success",
  //         timer: 1000, // Set the timer to 1000 milliseconds (1 second)
  //         showConfirmButton: false, // Hide the Confirm button
  //       })
  //     })
  //     .catch(error => console.log("error", error))
  // }
  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
    }
  }, [kondisiSoal])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  const [openAccordions, setOpenAccordions] = useState(true)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          <div className="accordion m-3" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames(
                    "accordion-button",
                    "fw-medium",
                    { collapsed: !openAccordions }
                  )}
                  type="button"
                  onClick={() => toggleAccordion()}
                  style={{ cursor: "pointer" }}
                >
                  Instruksi
                </button>
              </h2>

              <Collapse
                isOpen={openAccordions}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <CardBody className="bg-light rounded rounded-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instruksi,
                      }}
                    />
                  </CardBody>
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </Row>
      {kondisiSoal === false && (
        <div className="alert alert-primary d-flex align-items-center justify-content-center">
          <p
            className="fs-5 fw-medium ms-3 text-center"
            style={{ lineHeight: "1" }}
          >
            <MemoizedCountdownTimer
              durationInMinutes={props.waktu}
              setStatusTimer={setStatusTimer}
            />
          </p>
        </div>
      )}
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={4}>
          <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
            <CardBody style={{ flex: 1, overflow: "auto" }}>
              <p className="card-title">1. {props.skenario.judul_skenario}</p>
              <div className="mt-3">
                <hr
                  size="5"
                  width="100%"
                  className="border-primary border-2 border border-opacity-10"
                />
              </div>
              <p className="card-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.skenario.isi_skenario,
                  }}
                ></div>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6}>
          {Array.isArray(props.data) ? (
            props.idLayout === 1 ||
              props.idLayout === 2 ||
              props.idLayout === 3 ||
              props.idLayout === 4 ? (
              <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height">
                <CardBody style={{ flex: 1, overflow: "auto" }}>
                  <Col xl={12}>
                    {filteredDataPetunjuk.length > 0 && kondisiSoal
                      ? filteredDataPetunjuk
                        .slice(startIndex, endIndex)
                        .map((item, index) => (
                          <div key={index}>
                            <Card
                              className="border border-primary d-flex flex-column mh-25 full-height"
                              style={
                                customLayout != null
                                  ? customLayoutCardSoal
                                  : null
                              }
                            >
                              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                                <label className="mt-1"> Soal Petunjuk</label>
                              </CardHeader>
                              <CardBody className="text-left">
                                <p className="card-text mb-3 fw-semibold fs-5">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutSoal
                                        : null
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: item.soal,
                                    }}
                                  />
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col xl="12">
                                    <Row>
                                      {item.pilihan_jawaban.map(
                                        (item2, index2) => (
                                          <Col
                                            xl={12}
                                            md={12}
                                            sm={12}
                                            key={index2}
                                          >
                                            <label
                                              className="card-radio-label"
                                              style={
                                                customLayout != null
                                                  ? customLayoutCardJawaban
                                                  : null
                                              }
                                            >
                                              <input
                                                type="radio"
                                                name={`pilihan_${index}`}
                                                onClick={() =>
                                                  handleRadioChangePetunjuk(
                                                    item.id_msoal,
                                                    item.mt_idsubtest,
                                                    item.no_soal,
                                                    item.soal,
                                                    item2.id_soal_jawaban,
                                                    item2.pilihan,
                                                    item2.value,
                                                    item2.id_indikator
                                                  )
                                                }
                                                className="card-radio-input"
                                                checked={handleCheckedPetunjuk(
                                                  item2.id_soal_jawaban
                                                )}
                                              />

                                              <div className="card-radio border border-primary d-flex">
                                                <div
                                                  style={
                                                    customLayout != null
                                                      ? customLayoutJawaban
                                                      : {
                                                        width: "100%",
                                                        wordWrap:
                                                          "break-word",
                                                        whiteSpace:
                                                          "normal",
                                                      }
                                                  }
                                                  className="mt-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item2.pilihan,
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        ))
                      : filteredDataSoal
                        .slice(startIndex, endIndex)
                        .map((item, index) => (
                          <div key={index}>
                            <Card
                              className="border border-primary d-flex flex-column mh-25 full-height"
                              style={
                                customLayout != null
                                  ? customLayoutCardSoal
                                  : null
                              }
                            >
                              <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                                <label className="mt-1"> Soal</label>

                                <Button
                                  className="float-end btn btn-danger"
                                  color={
                                    tandaiState.includes(page - 1)
                                      ? "danger"
                                      : "primary"
                                  }
                                  onClick={handleTandaiSoal}
                                >
                                  {tandaiState.includes(page - 1)
                                    ? "Hapus Penandaan"
                                    : "Tandai"}
                                </Button>
                              </CardHeader>
                              <CardBody className="text-left">
                                <p className="card-text mb-3 fw-semibold fs-5">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutSoal
                                        : null
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: item.soal,
                                    }}
                                  />
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col xl="12">
                                    <Row>
                                      {item.pilihan_jawaban.map(
                                        (item2, index2) => (
                                          <Col
                                            xl={12}
                                            md={12}
                                            sm={12}
                                            key={index2}
                                          >
                                            <label className="card-radio-label">
                                              <input
                                                type="radio"
                                                name={`pilihan_${index}`}
                                                onClick={() =>
                                                  handleRadioChange(
                                                    item.id_msoal,
                                                    item.mt_idsubtest,
                                                    item.no_soal,
                                                    item2.id_soal_jawaban,
                                                    item2.value,
                                                    item2.id_indikator
                                                  )
                                                }
                                                className="card-radio-input"
                                                checked={handleChecked(
                                                  item2.id_soal_jawaban
                                                )}
                                              />
                                              <div
                                                className="card-radio border border-primary d-flex"
                                                style={
                                                  customLayout != null
                                                    ? customLayoutCardJawaban
                                                    : null
                                                }
                                              >
                                                <div
                                                  style={
                                                    customLayout != null
                                                      ? customLayoutJawaban
                                                      : {
                                                        width: "100%",
                                                        wordWrap:
                                                          "break-word",
                                                        whiteSpace:
                                                          "normal",
                                                      }
                                                  }
                                                  className="mt-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item2.pilihan,
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                                      <PaginationItem
                                        disabled={page === 1}
                                        style={{
                                          margin: "0 5px",
                                          flex: 2,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <PaginationLink
                                          className="fs-5"
                                          style={{
                                            borderRadius: "4px",
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          previous
                                          onClick={e => {
                                            e.preventDefault()
                                            setPage(page - 1)
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              transform: "rotate(180deg)",
                                            }}
                                          >
                                            &#10140;
                                          </span>{" "}
                                          Sebelumnya
                                        </PaginationLink>
                                      </PaginationItem>
                                      <PaginationItem
                                        disabled={page === totalPage}
                                        style={{
                                          margin: "0 5px",
                                          flex: 2,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <PaginationLink
                                          className="fs-5"
                                          style={{
                                            borderRadius: "4px",
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          next
                                          onClick={e => {
                                            e.preventDefault()
                                            setPage(page + 1)
                                          }}
                                        >
                                          Selanjutnya &#10140;
                                        </PaginationLink>
                                      </PaginationItem>
                                    </ul>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                  </Col>
                </CardBody>
              </Card>
            ) : (
              <>
                {soalPetunjuk.length > 0 && kondisiSoal
                  ? soalPetunjuk
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <Card
                        className="border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height"
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                        key={index}
                      >
                        <CardBody style={{ flex: 1, overflow: "auto" }}>
                          <div className="d-flex">
                            <p
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              className="card-title"
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            ></p>
                          </div>
                          <hr
                            size="5"
                            width="100%"
                            className="border-primary border-2 border border-opacity-10"
                          />
                          <table className="table table-striped text-center">
                            <thead>
                              <tr>
                                <th></th>
                                {JSON.parse(item.skala).map(
                                  (item3, index3) => (
                                    <th key={index3}>{item3}</th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {item.pilihan_jawaban &&
                                item.pilihan_jawaban.length > 0 &&
                                item.pilihan_jawaban.map((item2, index2) => (
                                  <tr key={index2}>
                                    <td className="text-start">
                                      <p
                                        style={
                                          customLayout != null
                                            ? customLayoutSoal
                                            : null
                                        }
                                        className="fw-normal"
                                        dangerouslySetInnerHTML={{
                                          __html: item2.pilihan,
                                        }}
                                      ></p>
                                    </td>
                                    {JSON.parse(item2.value).map(
                                      (item4, index4) => (
                                        <td key={index4}>
                                          <Input
                                            type="radio"
                                            className="form-check-input border border-2 border-primary"
                                            name={`pilihan_${index}${index2}`}
                                            onClick={() =>
                                              handleRadioChangePetunjuk(
                                                item.id_msoal,
                                                item.mt_idsubtest,
                                                item.no_soal,
                                                item.soal,
                                                item2.id_soal_jawaban,
                                                item2.pilihan,
                                                item4,
                                                JSON.parse(item.skala),
                                                index4,
                                                item2.id_indikator
                                              )
                                            }
                                            checked={selectedOptionsPetunjuk.some(
                                              option =>
                                                option.id_msoal ===
                                                item.id_msoal &&
                                                option.id_soal_jawaban ===
                                                item2.id_soal_jawaban &&
                                                option.index === index4
                                            )}
                                          />
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    ))
                  : dataSoal.slice(startIndex, endIndex).map((item, index) => (
                    <Card
                      className="border border-primary shadow shadow-lg d-flex flex-column mh-25 full-height"
                      style={
                        customLayout != null ? customLayoutCardSoal : null
                      }
                      key={index}
                    >
                      <CardBody style={{ flex: 1, overflow: "auto" }}>
                        <div className="d-flex">
                          <p
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            className="card-title"
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          ></p>
                        </div>
                        <hr
                          size="5"
                          width="100%"
                          className="border-primary border-2 border border-opacity-10"
                        />
                        <table className="table table-striped text-center">
                          <thead>
                            <tr>
                              <th></th>
                              {JSON.parse(item.skala).map((item3, index3) => (
                                <th key={index3}>{item3}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {item.pilihan_jawaban &&
                              item.pilihan_jawaban.length > 0 &&
                              item.pilihan_jawaban.map((item2, index2) => (
                                <tr key={index2}>
                                  <td className="text-start">
                                    <p
                                      style={
                                        customLayout != null
                                          ? customLayoutSoal
                                          : null
                                      }
                                      className="fw-normal"
                                      dangerouslySetInnerHTML={{
                                        __html: item2.pilihan,
                                      }}
                                    ></p>
                                  </td>
                                  {JSON.parse(item2.value).map(
                                    (item4, index4) => (
                                      <td key={index4}>
                                        <Input
                                          type="radio"
                                          className="form-check-input border border-2 border-primary"
                                          name={`pilihan_${index}${index2}`}
                                          onClick={() =>
                                            handleRadioChangePetunjuk(
                                              item.id_msoal,
                                              item.mt_idsubtest,
                                              item.no_soal,
                                              item.soal,
                                              item2.id_soal_jawaban,
                                              item2.pilihan,
                                              item4,
                                              JSON.parse(item.skala),
                                              index4,
                                              item2.id_indikator
                                            )
                                          }
                                          checked={selectedOptionsPetunjuk.some(
                                            option =>
                                              option.id_msoal ===
                                              item.id_msoal &&
                                              option.id_soal_jawaban ===
                                              item2.id_soal_jawaban &&
                                              option.index === index4
                                          )}
                                        />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  ))}
                <Row>
                  <Col lg="12">
                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                      <PaginationItem
                        disabled={page === 1}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          previous
                          onClick={e => {
                            e.preventDefault()
                            setPage(page - 1)
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              transform: "rotate(180deg)",
                            }}
                          >
                            &#10140;
                          </span>{" "}
                          Sebelumnya
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        disabled={page === totalPage}
                        style={{
                          margin: "0 5px",
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PaginationLink
                          className="fs-5"
                          style={{
                            borderRadius: "4px",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          next
                          onClick={e => {
                            e.preventDefault()
                            setPage(page + 1)
                          }}
                        >
                          Selanjutnya &#10140;
                        </PaginationLink>
                      </PaginationItem>
                    </ul>
                  </Col>
                </Row>
              </>
            )
          ) : (
            <p>props.data is not an array.</p>
          )}
        </Col>
        <Col xl={2}>
          {/* <Card
            className="bg-transparent border border-primary shadow shadow-lg"
            style={{ height: "30rem" }}
          >
            <CardHeader className="fs-5 fw-semibold bg-primary text-light">
              Nomer Soal
            </CardHeader>
            <CardBody>
              <Row>
                {props.data.map((item, index) => (
                  <Col xl={2} key={index}>
                    <label className="card-radio-label">
                      <input
                        type="radio"
                        onClick={() => setPage(index + 1)}
                        className="card-radio-input"
                        checked={
                          props.idLayout === 1 ||
                          props.idLayout === 2 ||
                          props.idLayout === 3 ||
                          props.idLayout === 4
                            ? selectedOptions.findIndex(
                                option => option.id_msoal === item.id_msoal
                              ) === index
                            : props.idLayout === 6 || props.idLayout === 14
                            ? selectedOptions.some(
                                option => option.id_msoal === item.id_msoal
                              )
                            : ""
                        }
                      />

                      <div className="card-radio">{index + 1}</div>
                    </label>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card> */}
          <HistorySoal
            data={kondisiSoal ? soalPetunjuk : dataSoal}
            selectedOptions={
              kondisiSoal ? selectedOptionsPetunjuk : selectedOptions
            }
            setSoal={setPage}
          />
        </Col>
      </Row>
      {/* {((isLastPage && (props.idLayout === 6 || props.idLayout === 14)) || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
          />
        </Row>
      )} */}
    </div>
  )
}

export default sjt

import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import imagesoal from "../../../../src/assets/images/jobs.png"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const soal_text = props => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const data = props.data
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const customLayout = props.customLayout
  const [statusTImer, setStatusTimer] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [dataPage, setDataPage] = useState()

  const navigate = useNavigate()
  const [textareaValues, setTextareaValues] = useState(
    Array(data.length).fill("")
  )

  const [textareaValuesSoal, setTextareaValuesSoal] = useState(
    Array(dataSoal.length).fill("")
  )
  const [matchedData, setMatchedData] = useState([])

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const handleTextareaChange = (index, event) => {
    const updatedTextareaValues = [...textareaValues]
    updatedTextareaValues[index] = event.target.value.toLowerCase() // Konversi ke huruf kecil
    setTextareaValues(updatedTextareaValues)

    // Collect both "kunci" dan "value" dan hapus duplikat
    const pilihanJawaban = props.data[index].pilihan_jawaban
    const kunciDanValue = pilihanJawaban.map(item => ({
      kunci: item.kunci.toLowerCase(), // Konversi ke huruf kecil
      value: item.value.toLowerCase(), // Konversi ke huruf kecil
      id_soal_jawaban: item.id_soal_jawaban,
      id_msoal: item.id_msoal,
      soal: props.data[index].soal,
      no_soal: props.data[index].no_soal,
      pilihan: updatedTextareaValues[index],
      ju_id_indikator_jawaban: item.id_indikator
    }))
    const uniqueKunciDanValue = [...new Set(kunciDanValue)]

    // Mencari kata yang cocok dalam textareaValues dengan kunci
    const matchedItems = uniqueKunciDanValue.filter(pair => {
      const words = updatedTextareaValues[index].split(/[,\.\s]/)
      return words.some(word => word === pair.kunci)
    })

    if (matchedItems.length > 0) {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = matchedItems
        return newData
      })
    } else {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = {
          id_msoal: props.data[index].id_msoal,
          soal: props.data[index].soal,
          value: "0",
          id_soal_jawaban: null,
          no_soal: props.data[index].no_soal,
          pilihan: updatedTextareaValues[index],
          ju_id_indikator_jawaban: kunciDanValue.ju_id_indikator_jawaban
        }
        return newData
      })
    }
  }

  const handleTextareaChangeSoal = (index, event) => {
    const updatedTextareaValues = [...textareaValuesSoal]
    updatedTextareaValues[index] = event.target.value.toLowerCase() // Konversi ke huruf kecil
    setTextareaValuesSoal(updatedTextareaValues)

    // Collect both "kunci" dan "value" dan hapus duplikat
    const pilihanJawaban = props.dataSoal[index].pilihan_jawaban
    const kunciDanValue = pilihanJawaban.map(item => ({
      kunci: item.kunci.toLowerCase(), // Konversi ke huruf kecil
      value: item.value.toLowerCase(), // Konversi ke huruf kecil
      id_soal_jawaban: item.id_soal_jawaban,
      id_msoal: item.id_msoal,
      soal: props.dataSoal[index].soal,
      no_soal: props.dataSoal[index].no_soal,
      pilihan: updatedTextareaValues[index],
      ju_id_indikator_jawaban: item.id_indikator
    }))
    const uniqueKunciDanValue = [...new Set(kunciDanValue)]

    // Mencari kata yang cocok dalam textareaValues dengan kunci
    const matchedItems = uniqueKunciDanValue.filter(pair => {
      const words = updatedTextareaValues[index].split(/[,\.\s]/)
      return words.some(word => word === pair.kunci)
    })

    if (matchedItems.length > 0) {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = matchedItems
        return newData
      })
    } else {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = {
          id_msoal: props.dataSoal[index].id_msoal,
          soal: props.dataSoal[index].soal,
          value: "0",
          id_soal_jawaban: null,
          no_soal: props.dataSoal[index].no_soal,
          pilihan: updatedTextareaValues[index],
          ju_id_indikator_jawaban: kunciDanValue.ju_id_indikator_jawaban
        }
        return newData
      })
    }
  }
  const allMatchedData = matchedData.flatMap(data => data)
  //set Text
  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/jawabanText?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      props.idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setTextareaValues(responseData.data[0])
        }
      })
      .catch(error => console.log("error", error))
  }, [])

  //set value
  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/viewJawaban?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      props.idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setMatchedData(responseData.data[0])
        }
      })
      .catch(error => {
        // Swal.fire({
        //   title: "Terjadi Kesalahan",
        //   icon: "error",
        //   timer: 1000,
        //   showConfirmButton: false,
        // })
      })
  }, [])

  const inputDataTemporary = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("id_jadwal", DecodeJwt.id_jadwal)
    formdata.append("nip", DecodeJwt.nip)
    formdata.append("id_alattest", props.idAlattest)
    formdata.append("id_subtest", props.idSubtest)
    formdata.append("data", JSON.stringify(matchedData))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
      requestOptions
    )
      .then(response => response.text())
      .then(result => { })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        Swal.fire({
          title: "Terjadi kesalahan, Silahkan hubungi admin",
          icon: "error",
        })
      })
  }
  const HandlleNextPageAndSaveJawban = value => {
    setPage(value)
    // Swal.fire({
    //   title: "Proses data...",
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading()
    //   },
    //   showConfirmButton: false,
    // })

    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/jawabanText?" +
      "id_subtest=" +
      props.idSubtest +
      "&id_alattest=" +
      props.idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip +
      "&data=" +
      JSON.stringify(textareaValuesSoal),
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          inputDataTemporary()
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        // Swal.fire({
        //   title: "Terjadi kesalahan",
        //   icon: "error",
        // })
      })
  }
  const saveAllData = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("id_jadwal", DecodeJwt.id_jadwal)
    formdata.append("nip", DecodeJwt.nip)
    formdata.append("id_alattest", props.idAlattest)
    formdata.append("id_subtest", props.idSubtest)
    formdata.append("data", JSON.stringify(allMatchedData))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          var myHeaders = new Headers()
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token")
          )

          var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow",
          }

          fetch(
            process.env.REACT_APP_BACKEND_URL_VERSION +
            "jawaban/updateStatus?idSubtest=" +
            idSubtest +
            "&idAlattest=" +
            props.idAlattest +
            "&nip=" +
            DecodeJwt.nip +
            "&id_jadwal=" +
            DecodeJwt.id_jadwal +
            "&id_asesi=" +
            DecodeJwt.id_jadwal_asesi,
            requestOptions
          )
            .then(response => response.text())
            .then(result => {
              const responseData = JSON.parse(result)
              if (responseData.meta.code == 200) {
                // Swal.fire({
                //   title: "Proses selesai!",
                //   icon: "success",
                //   timer: 1000, // Set the timer to 1000 milliseconds (1 second)
                //   showConfirmButton: false, // Hide the Confirm button
                // })
                navigate("/soalListAlatTes")
              }
            })
            .catch(error => console.log("error", error))
        }
      })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        // Swal.fire({
        //   title: "Terjadi kesalahan",
        //   icon: "error",
        // })
      })
  }

  const handleSaveJawaban = () => {
    // Swal.fire({
    //   title: "Proses data...",
    //   text: "Mohon tunggu sebentar...", // Optional text to provide additional information
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading()
    //   },
    //   showConfirmButton: false,
    // })
    saveAllData()
  }

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
    }
  }, [kondisiSoal])

  const changeStatus = () => {
    setkondisiSoal(false);
  }
  return (
    <div>
      <Row>
        <div className="alert alert-success w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            {kondisiSoal
              ? "Berikut Adalah Soal Petunjuk"
              : "Pastikan anda mengisi semua soal"}
          </p>
        </div>
        {props.waktu && kondisiSoal === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={props.waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>

      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={10} >
          {soalPetunjuk.length > 0 && kondisiSoal ? soalPetunjuk.slice(startIndex, endIndex).map((item, index) => (
            <Card className="border border-primary shadow shadow-lg d-flex flex-column mh-25 h-100"
              style={customLayout != null ? customLayoutCardSoal : null}
              key={startIndex + index}
            >
              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <span className="fw-bold">{`Question ${startIndex + index + 1
                  }`}</span>
                <br />
                <div
                  style={customLayout != null ? customLayoutSoal : null}
                  dangerouslySetInnerHTML={{
                    __html: item.soal,
                  }}
                />

                <div className="mt-3">
                  <hr size="5" width="100%" className="bg-light" />
                </div>
                <textarea
                  rows={5}
                  className="form-control border border-0 border-primary"
                  value={textareaValues[startIndex + index]}
                  onChange={event =>
                    handleTextareaChange(startIndex + index, event)
                  }
                />
              </CardBody>
            </Card>
          )) : dataSoal.slice(startIndex, endIndex).map((item, index) => (
            <Card className="border border-primary shadow shadow-lg d-flex flex-column mh-25 h-100"
              style={customLayout != null ? customLayoutCardSoal : null}
              key={startIndex + index}
            >
              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <span className="fw-bold">{`Question ${startIndex + index + 1
                  }`}</span>
                <br />
                <div
                  style={customLayout != null ? customLayoutSoal : null}
                  dangerouslySetInnerHTML={{
                    __html: item.soal,
                  }}
                />

                <div className="mt-3">
                  <hr size="5" width="100%" className="bg-light" />
                </div>
                <textarea
                  rows={5}
                  className="form-control border border-0 border-primary"
                  value={textareaValuesSoal[startIndex + index]}
                  onChange={event =>
                    handleTextareaChangeSoal(startIndex + index, event)
                  }
                />
              </CardBody>
            </Card>
          ))}
          <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                <PaginationItem disabled={page === 1} style={{ margin: '0 5px', flex: 2, display: 'flex', alignItems: 'center' }}>
                  <PaginationLink
                    className="fs-5"
                    style={{ borderRadius: "4px", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    previous
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page - 1);
                    }}
                  >
                    <span style={{ display: 'inline-block', transform: 'rotate(180deg)' }}>&#10140;</span> Sebelumnya
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={page === totalPage} style={{ margin: '0 5px', flex: 2, display: 'flex', alignItems: 'center' }}>
                  <PaginationLink
                    className="fs-5"
                    style={{ borderRadius: "4px", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    next
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    Selanjutnya &#10140;
                  </PaginationLink>
                </PaginationItem>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col xl={2}>
          <HistorySoal
            data={kondisiSoal ? soalPetunjuk : dataSoal}
            selectedOptions={allMatchedData}
            setSoal={setPage}
          />
        </Col>
      </Row>
      {kondisiSoal === true && (
        <Row>
          <Col xl={12} style={{ marginTop: "10vh" }}>
            <Card className="shadow shadow-lg">
              <CardBody className="text-end">
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => changeStatus()}
                >
                  Mulai Kerjakan
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {isLastPage && kondisiSoal === false && (
        <Row>
          <Col xl={12} style={{ marginTop: "10vh" }}>
            <Card className="shadow shadow-lg">
              <CardBody className="text-end">
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => handleSaveJawaban()}
                >
                  Simpan Jawaban
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default soal_text

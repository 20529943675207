import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"

const multiple_ca = ({
  idAlattest,
  idSubtest,
  jenisSoal,
  caseId,
  urutanCase,
  setUrutanCase,
  jumlahCase,
  setOptions,
  soalPetunjuk,
  dataSoal,
  setPetunjuk,
  customLayout
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [dataPage, setDataPage] = useState()
  const [data, setData] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    ju_id_indikator_jawaban
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const index = parseInt(no_soal) - 1

    for (let i = 0; i < data.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            mt_idsubtest,
            no_soal,
            id_soal_jawaban,
            value,
            ju_id_indikator_jawaban
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            mt_idsubtest,
            no_soal,
            id_soal_jawaban,
            value,
            ju_id_indikator_jawaban
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }



  const handleSaveJawaban = () => {
  }

  const handleSaveJawabanPetunjuk = () => {
    setSelectedOptions([])
    setkondisiSoal(false)
    setPage(1)
  }
  const handleChecked = id_soal_jawaban => {
    for (let index = 0; index < data.length; index++) {
      if (
        selectedOptions[index] &&
        selectedOptions[index].id_soal_jawaban === id_soal_jawaban
      ) {
        return true
      }
    }
  }

  const setIndexNextCase = () => {
    setUrutanCase(urutanCase + 1)
  }

  const setIndexBackCase = () => {
    setUrutanCase(urutanCase - 1)
  }


  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      const filteredData = soalPetunjuk.filter(item => item.case_id === caseId)
      setDataPage(filteredData.length)
      setData(soalPetunjuk)
    } else {
      const filteredData = dataSoal.filter(item => item.case_id === caseId)
      setDataPage(filteredData.length)
      setData(dataSoal)
      setPetunjuk(false)
    }
  }, [kondisiSoal])

  //pagination
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const filteredData = dataSoal.filter(item => item.case_id === caseId)
  const filteredDataPetunjuk = soalPetunjuk.filter(
    item => item.case_id === caseId
  )

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  return (
    <div>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col xl={12}>
              {filteredDataPetunjuk.length > 0 && kondisiSoal
                ? filteredDataPetunjuk
                  .slice(startIndex, endIndex)
                  .map((item, index) => (
                    <div key={index}>
                      <Card
                        className="border border-primary d-flex flex-column mh-25"
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                      >
                        <CardBody className="text-left">
                          <p className="card-text mb-3 fw-semibold fs-5">
                            <div
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            />
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Col xl="12">
                              <Row>
                                {item.pilihan_jawaban.map((item2, index2) => (
                                  <Col xl={12} md={12} sm={12} key={index2}>
                                    <label
                                      className="card-radio-label"
                                      style={
                                        customLayout != null
                                          ? customLayoutCardJawaban
                                          : null
                                      }
                                    >
                                      <input
                                        type="radio"
                                        name={`pilihan_${index}`}
                                        onClick={() =>
                                          handleRadioChange(
                                            item.id_msoal,
                                            item.mt_idsubtest,
                                            item.no_soal,
                                            item2.id_soal_jawaban,
                                            item2.value,
                                            item2.id_indikator
                                          )
                                        }
                                        className="card-radio-input"
                                        checked={handleChecked(
                                          item2.id_soal_jawaban
                                        )}
                                      />

                                      <div className="card-radio border border-primary d-flex">
                                        <div
                                          style={
                                            customLayout != null
                                              ? customLayoutJawaban
                                              : { width: "100%", wordWrap: "break-word", whiteSpace: "normal" }
                                          }
                                          className="mt-2"
                                          dangerouslySetInnerHTML={{
                                            __html: item2.pilihan,
                                          }}
                                        ></div>
                                      </div>
                                    </label>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  ))
                : filteredData
                  .slice(startIndex, endIndex)
                  .map((item, index) => (
                    <div key={index}>
                      <Card
                        className="border border-primary d-flex flex-column mh-25 full-height"
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                      >
                        <CardBody className="text-left">
                          <p className="card-text mb-3 fw-semibold fs-5">
                            <div
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            />
                          </p>
                          <Row className="d-flex justify-content-center">
                            <Col xl="12">
                              <Row>
                                {item.pilihan_jawaban.map((item2, index2) => (
                                  <Col xl={12} md={12} sm={12} key={index2}>
                                    <label className="card-radio-label">
                                      <input
                                        type="radio"
                                        name={`pilihan_${index}`}
                                        onClick={() =>
                                          handleRadioChange(
                                            item.id_msoal,
                                            item.mt_idsubtest,
                                            item.no_soal,
                                            item2.id_soal_jawaban,
                                            item2.value,
                                            item2.id_indikator
                                          )
                                        }
                                        className="card-radio-input"
                                        checked={handleChecked(
                                          item2.id_soal_jawaban
                                        )}
                                      />

                                      <div
                                        className="card-radio border border-primary border-1 d-flex"
                                        style={
                                          customLayout != null
                                            ? customLayoutCardJawaban
                                            : null
                                        }
                                      >
                                        <div
                                          style={
                                            customLayout != null
                                              ? customLayoutJawaban
                                              : { width: "100%", wordWrap: "break-word", whiteSpace: "normal" }
                                          }
                                          className="m-2"
                                          dangerouslySetInnerHTML={{
                                            __html: item2.pilihan,
                                          }}
                                        ></div>
                                      </div>
                                    </label>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  ))}

              {/* <Row>
                <Col lg="12">
                  <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        href="#"
                        onClick={() => setPage(page - 1)}
                      />
                    </PaginationItem>

                    {Array.from({ length: totalPage }).map((_, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink onClick={() => setPage(i + 1)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}

                    <PaginationItem disabled={page === totalPage}>
                      <PaginationLink
                        next
                        href="#"
                        onClick={() => setPage(page + 1)}
                      />
                    </PaginationItem>
                  </ul>
                </Col>
              </Row> */}
              {/* <Row>
                <Col xl={6}>
                  <div className="text-start">
                    {isLastPage && urutanCase > 0 && (
                      <button
                        className="btn btn-primary"
                        onClick={() => setIndexBackCase()}
                      >
                        <i className="bx bx-left-arrow-alt"></i>
                      </button>
                    )}
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="text-end">
                    {isLastPage && urutanCase + 1 < jumlahCase ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => setIndexNextCase()}
                      >
                        <i className="bx bx-right-arrow-alt"></i>
                      </button>
                    ) : kondisiSoal ? (
                      <button
                        className="btn-primary btn rounded rounded-3"
                        onClick={() => handleSaveJawabanPetunjuk()}
                      >
                        Mulai Kerjakan
                      </button>
                    ) : (
                      <button
                        className="btn-primary btn rounded rounded-3"
                        onClick={() => handleSaveJawaban()}
                      >
                        Simpan Jawaban
                      </button>
                    )}
                  </div>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

export default multiple_ca

import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
  Collapse,
  CardHeader,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import { data } from "autoprefixer"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"

const MemoizedCountdownTimer = React.memo(CountdownTimer)

const tipeA = ({
  data,
  idAlattest,
  idSubtest,
  jenisSoal,
  caseId,
  urutanCase,
  setUrutanCase,
  jumlahCase,
  setOptions,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout,
  subTest,
  showInstruksi,
  showTimer,
  isExpand,
  onExpandToggle,
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionsPetunjuk, setSelectedOptionsPetunjuk] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState(false)
  const [page, setPage] = useState(1)
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    ju_id_indikator_jawaban
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const index = parseInt(no_soal) - 1

    console.log(no_soal)
    for (let i = 0; i < dataSoal.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            mt_idsubtest,
            no_soal,
            soal,
            id_soal_jawaban,
            pilihan,
            value,
            ju_id_indikator_jawaban,
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            mt_idsubtest,
            no_soal,
            soal,
            id_soal_jawaban,
            pilihan,
            value,
            ju_id_indikator_jawaban,
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }
  const handleRadioChangePetunjuk = (id_msoal, id_soal_jawaban, no_soal) => {
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]
    const index = parseInt(no_soal) - 1
    for (let i = 0; i < soalPetunjuk.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            id_soal_jawaban,
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            id_soal_jawaban,
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  const getDataJawaban = () => { }

  const handleChecked = id_soal_jawaban => {
    for (let index = 0; index < dataSoal.length; index++) {
      if (
        selectedOptions[index] &&
        selectedOptions[index].id_soal_jawaban === id_soal_jawaban
      ) {
        return true
      }
    }
  }
  const handleCheckedPetunjuk = id_soal_jawaban => {
    for (let index = 0; index < soalPetunjuk.length; index++) {
      if (
        selectedOptionsPetunjuk[index] &&
        selectedOptionsPetunjuk[index].id_soal_jawaban === id_soal_jawaban
      ) {
        return true
      }
    }
  }

  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
    }
  }, [kondisiSoal])

  console.log("soal=-petunjuk : " + soalPetunjuk.length)
  //pagination
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const totalPage = Math.ceil(dataPage / itemsPerPage)
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  console.log(dataSoal)

  const [openAccordions, setOpenAccordions] = useState(isExpand)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(9)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setSoalState(soalState === 9 ? 12 : 9)
    setPetunjukStatus(!petunjukStatus)
    onExpandToggle() // Notify parent to update state
  }

  return (
    <div>
      <Row>
        {!showInstruksi && (
          <div className="accordion m-3" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button", "fw-medium", "fs-1", {
                    collapsed: !openAccordions,
                  })}
                  type="button"
                  onClick={() => toggleAccordion()}
                  style={{ cursor: "pointer" }}
                >
                  Instruksi
                </button>
              </h2>

              <Collapse isOpen={openAccordions} className="accordion-collapse">
                <div className="accordion-body">
                  <CardBody className="bg-light rounded rounded-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subTest?.instruksi,
                      }}
                    />
                  </CardBody>
                </div>
              </Collapse>
            </div>
          </div>
        )}
        {waktu && kondisiSoal && !showTimer === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Card>
        <CardBody>
          <Row>
            <Col xl={soalState}>
              {soalPetunjuk.length > 0 && kondisiSoal
                ? soalPetunjuk
                  .slice(startIndex, endIndex)
                  .map((item, index) => (
                    <Card
                      className=" bg-transparent border border-primary shadow shadow-lg d-flex flex-column  full-height"
                      key={index}
                      style={{ flex: 1, overflow: "auto" }}
                    >
                      <CardBody>
                        <p className="card-text mb-3 fw-bold fs-4 text-left">
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          />
                        </p>
                        <Row className="d-flex justify-content-center">
                          {item.pilihan_jawaban.map((item2, index2) => (
                            <Col xl="12" sm="12" key={index2}>
                              <label
                                className="card-radio-label"
                                style={
                                  customLayout != null
                                    ? customLayoutCardJawaban
                                    : null
                                }
                              >
                                <input
                                  type="radio"
                                  name={`pilihan_${startIndex + index}`}
                                  onClick={() =>
                                    handleRadioChangePetunjuk(
                                      item.id_msoal,
                                      item2.id_soal_jawaban,
                                      item.no_soal
                                    )
                                  }
                                  className="card-radio-input"
                                  checked={handleCheckedPetunjuk(
                                    item2.id_soal_jawaban
                                  )}
                                />

                                <div className="card-radio border border-primary border-1">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutJawaban
                                        : null
                                    }
                                    className="mt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: item2.pilihan,
                                    }}
                                  ></div>
                                </div>
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  ))
                : dataSoal.slice(startIndex, endIndex).map((item, index) => (
                  <Card
                    className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column  full-height"
                    key={index}
                    style={{ flex: 1, overflow: "auto" }}
                  >
                    <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                      <label className="mt-1">
                        {" "}
                        Soal Nomor {item.no_soal}
                      </label>

                      <button
                        type="button"
                        onClick={() => {
                          expand()
                        }}
                        className="btn noti-icon text-white float-end"
                      >
                        <i
                          className="bx bx-fullscreen"
                          style={{ color: "white" }}
                        />
                      </button>
                    </CardHeader>
                    <CardBody className="text-left">
                      {/* <p className="card-text mb-3 fw-bold fs-4">
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          />
                        </p> */}
                      <CardBody className="text-start" style={{ flex: 1 }}>
                        <p className="card-text mb-3 fw-bold fs-4">
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          />
                        </p>
                        <Row className="d-flex justify-content-center">
                          {item.pilihan_jawaban.map((item2, index2) => (
                            <Col xl="12" sm="12" key={index2}>
                              <label
                                className="card-radio-label"
                                style={
                                  customLayout != null
                                    ? customLayoutCardJawaban
                                    : null
                                }
                              >
                                <input
                                  type="radio"
                                  name={`pilihan_${startIndex + index}`}
                                  onClick={() =>
                                    handleRadioChange(
                                      item.id_msoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item2.id_soal_jawaban,
                                      item2.value,
                                      item2.id_indikator,
                                      item.id_indikator
                                    )
                                  }
                                  className="card-radio-input"
                                  checked={handleChecked(
                                    item2.id_soal_jawaban
                                  )}
                                />

                                <div className="card-radio border border-primary border-1 d-flex">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutJawaban
                                        : {
                                          width: "100%",
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                        }
                                    }
                                    className="mt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: item2.pilihan,
                                    }}
                                  ></div>
                                </div>
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </CardBody>
                  </Card>
                ))}
              {/* <Row>
                <Col lg="12">
                  <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        href="#"
                        onClick={() => setPage(page - 1)}
                      />
                    </PaginationItem>

                    {Array.from({ length: totalPage }).map((_, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink onClick={() => setPage(i + 1)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}

                    <PaginationItem disabled={page === totalPage}>
                      <PaginationLink
                        next
                        href="#"
                        onClick={() => setPage(page + 1)}
                      />
                    </PaginationItem>
                  </ul>
                </Col>
              </Row> */}
            </Col>
            <Col xl={3}>
              {petunjukStatus && (
                <HistorySoal
                  data={data}
                  selectedOptions={
                    kondisiSoal ? selectedOptionspetunjuk : selectedOptions
                  }
                  setSoal={setPage}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* {(isLastPage === true || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
          />
        </Row>
      )} */}
    </div>
  )
}

export default tipeA

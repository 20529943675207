import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
  CardHeader,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer)

const matrix_ca = ({
  idLayout,
  skenario,
  idAlattest,
  idSubtest,
  caseId,
  setUrutanCase,
  urutanCase,
  jumlahCase,
  setOptions,
  statusTImer,
  customLayout,
  activeIndex,
  setActiveIndex,
  soalPetunjuk,
  dataSoal,
  setPetunjuk,
  selectedOptionsPetunjuk,
  setSelectedOptionsPetunjuk,
  updateCaseDetails,
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [dataPage, setDataPage] = useState()
  const [data, setData] = useState()
  const [selectedOptionspetunjuk, setSelectedOptionspetunjuk] = useState([])
  const [totalJawaban, setTotalJawaban] = useState(0)
  const [answeredCounts, setAnsweredCounts] = useState({})
  const [totalAnswered, setTotalAnswered] = useState(0)
  const [totalJawabanByQuestion, setTotalJawabanByQuestion] = useState({})
  const [TotalSemuaJawaban, setTotalSemuaJawaban] = useState(0)

  const setIndexNextCase = () => {
    setUrutanCase(urutanCase + 1)
  }

  const setIndexBackCase = () => {
    setUrutanCase(urutanCase - 1)
  }

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      const filteredData = soalPetunjuk
      setDataPage(filteredData.length)
      setData(soalPetunjuk)
      setPetunjuk(true)

      // Console log soal yang ditampilkan dan ID-nya
      filteredData.forEach(item => {
        console.log("Soal Petunjuk:", item.soal, "ID:", item.id_msoal)
      })
    } else {
      const filteredData = dataSoal
      setDataPage(filteredData.length)
      setData(dataSoal)
      setPetunjuk(false)

      // Console log soal yang ditampilkan dan ID-nya
      filteredData.forEach(item => {
        console.log("Soal Biasa:", item.soal, "ID:", item.id_msoal)
      })
    }
  }, [kondisiSoal])

  //pagination
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const filteredData = dataSoal
  const filteredDataPetunjuk = soalPetunjuk
  const [totalPage, setTotalPage] = useState(Math.ceil(dataPage / itemsPerPage))
  useEffect(() => {
    setTotalPage(Math.ceil(dataPage / itemsPerPage))
  }, [dataPage])

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const sortedData = _.sortBy(selectedOptions, ["no_soal"])

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    id_soal_jawaban,
    value,
    id_aspekat,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      id_soal_jawaban,
      value,
      id_aspekat,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)

    // Update answeredQuestions set
    const updatedAnsweredQuestions = new Set(answeredQuestions)
    updatedAnsweredQuestions.add(id_msoal) // Add the question ID to the set

    setAnsweredQuestions(updatedAnsweredQuestions)

    // Update totalJawaban with the size of answeredQuestions set
    setTotalJawaban(updatedAnsweredQuestions.size)

    // Update TotalSemuaJawaban
    setTotalSemuaJawaban(updatedAnsweredQuestions.size)

    // Log totalJawaban
    console.log("Total Jawaban:", updatedAnsweredQuestions.size)
  }

  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    id_aspekat,
    skala,
    index
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionspetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_aspekat,
      skala: skala[index],
      index,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptionspetunjuk(updatedSelectedOptions)
  }
  useEffect(() => {
    if (selectedOptions.length > 0) {
      setOptions(selectedOptions)
      // Swal.fire({
      //   title: "Proses data...",
      //   allowOutsideClick: false,
      //   onBeforeOpen: () => {
      //     Swal.showLoading()
      //   },
      //   showConfirmButton: false,
      // })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )
      var formdata = new FormData()
      formdata.append("id_jadwal", DecodeJwt.id_jadwal)
      formdata.append("nip", DecodeJwt.nip)
      formdata.append("id_alattest", idAlattest)
      formdata.append("id_subtest", idSubtest)
      formdata.append("data", JSON.stringify(selectedOptions))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          // if (error) {
          //   // Swal.fire({
          //   //   title: "skala sudah terpilih cari skala lain",
          //   //   icon: "error",
          //   //   timer: 3000,
          //   //   showConfirmButton: false,
          //   // })
          // } else {
          // //   Swal.fire({
          // //     title: "Proses selesai!",
          // //     icon: "success",
          // //     timer: 1000,
          // //     showConfirmButton: false,
          // //   })
          // }
        })
        .catch(error => {
          console.log("error", error)
          // Menutup SweetAlert jika terjadi kesalahan
          Swal.fire({
            title: "Terjadi kesalahan, Silahkan hubungi admin",
            icon: "error",
          })
        })
    } else {
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer" + localStorage.getItem("access_token")
      )

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "jawaban/viewJawaban?" +
        "id_subtest=" +
        idSubtest +
        "&id_alattest=" +
        idAlattest +
        "&id_jadwal=" +
        DecodeJwt.id_jadwal +
        "&nip=" +
        DecodeJwt.nip,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          const responseData = JSON.parse(result)
          if (responseData.meta.code == 200) {
            setSelectedOptions(responseData.data[0])
          }
        })
        .catch(error => {
          setkondisiSoal(true)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        })
    }
  }, [selectedOptions])

  const handleSaveJawaban = () => {
    console.log(TotalSemuaJawaban, dataSoal.length)
    if (TotalSemuaJawaban !== dataSoal.length) {
      Swal.fire({
        title: "Incomplete!",
        text: "Please make sure to complete all the questions first.",
        icon: "warning",
        confirmButtonText: "OK",
      })
      return null
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit your answers?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Proses data...",
          text: "Mohon tunggu sebentar...", // Optional text to provide additional information
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
          showConfirmButton: false,
        })

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        )

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/updateStatus?idSubtest=" +
          idSubtest +
          "&idAlattest=" +
          idAlattest +
          "&nip=" +
          DecodeJwt.nip +
          "&id_jadwal=" +
          DecodeJwt.id_jadwal +
          "&id_asesi=" +
          DecodeJwt.id_jadwal_asesi,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            navigate("/soalListAlatTes")
            Swal.fire({
              title: "Proses selesai!",
              icon: "success",
              timer: 1000, // Set the timer to 1000 milliseconds (1 second)
              showConfirmButton: false, // Hide the Confirm button
            })
          })
          .catch(error => console.log("error", error))
      }
    })
  }

  const handleSaveJawabanPetunjuk = () => {
    // setSelectedOptions([])
    setkondisiSoal(false)
    setPetunjuk(false)
    setPage(1)
  }

  const handleChecked = id_soal_jawaban => {
    if (data) {
      console.log("id soal jawaban :" + id_soal_jawaban)
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptions[index] &&
          selectedOptions[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  const handleCheckedPetunjuk = id_soal_jawaban => {
    if (data) {
      for (let index = 0; index < data.length; index++) {
        if (
          selectedOptionsPetunjuk[index] &&
          selectedOptionsPetunjuk[index].id_soal_jawaban === id_soal_jawaban
        ) {
          return true
        }
      }
    }
  }

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [data])

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [selectedOptions])

  useEffect(() => {
    // Create an object to keep track of total answers for each question number
    const totalAnswersByQuestion = _.countBy(selectedOptions, "no_soal")

    // Optionally, you can log or set this value to the state if needed
    console.log("Total Jawaban per Nomor Soal:", totalAnswersByQuestion)

    // If you want to set this information in a state for further use
    setTotalJawabanByQuestion(totalAnswersByQuestion)
  }, [selectedOptions])

  useEffect(() => {
    const counts = {}

    selectedOptions.forEach(option => {
      const { no_soal } = option
      if (!counts[no_soal]) {
        counts[no_soal] = 0
      }
      counts[no_soal] += 1
    })

    const total = Object.keys(counts).length

    setAnsweredCounts(counts)
    setTotalSemuaJawaban(total)

    // Log the data using console.table
    const tableData = Object.entries(counts).map(([questionNo, count]) => ({
      Nomor: questionNo,
      TotalJawaban: count,
      TotalSemuaJawaban: total,
    }))

    // console.table(tableData)
  }, [selectedOptions])

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  // useEffect untuk mengambil data case_name dan caseContent
  useEffect(() => {
    if (data && data.length > 0 && activeIndex < data.length) {
      const { case_name, case: caseContent } = data[activeIndex].case
      updateCaseDetails(case_name, caseContent)
    }
  }, [data, activeIndex])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  useEffect(() => {
    setTotalPage(Math.ceil(dataPage / itemsPerPage))
  }, [dataPage])

  useEffect(() => {
    // console.log(page)
  }, [urutanCase, page])

  useEffect(() => {
    setPage(activeIndex + 1)
    // console.log("INDEX HALAMAN SAAT INI: ", activeIndex)
  }, [activeIndex])

  const nextPage = page => {
    setPage(page + 1)
    setActiveIndex(page)
  }

  const prevPage = page => {
    setPage(page - 1)
    setActiveIndex(page - 2)
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          <div className="alert alert-success w-100 d-flex align-items-center">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Pastikan anda mengisi semua soal
            </p>
          </div>
        )}
      </Row>

      <Row>
        {filteredDataPetunjuk.length > 0 && kondisiSoal
          ? filteredDataPetunjuk
            .slice(startIndex, endIndex)
            .map((item, outerIndex) => (
              <Col xl={12} key={outerIndex}>
                <Card className="shadow shadow-lg d-flex flex-column">
                  <CardHeader className="d-flex border-bottom border-4 bg-white">
                    <p
                      style={customLayout != null ? customLayoutSoal : null}
                      className="card-title"
                      dangerouslySetInnerHTML={{
                        __html: item.soal,
                      }}
                    ></p>
                  </CardHeader>
                  <CardBody>
                    <table className="table table-striped">
                      <thead>
                        <tr className="text-start fw-bold">
                          <th></th>
                          {JSON.parse(item.skala).map((item2, index2) => (
                            <th key={index2}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item2,
                                }}
                              ></div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {item.pilihan_jawaban.map((item3, innerIndex) => (
                          <tr className="text-start" key={innerIndex}>
                            <td>
                              <div
                                style={
                                  customLayout != null
                                    ? customLayoutSoal
                                    : null
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item3.pilihan,
                                }}
                              ></div>
                            </td>
                            {JSON.parse(item3.value).map((item4, index4) => (
                              <td key={index4}>
                                <input
                                  type="radio"
                                  className="form-check-input border border-2 border-primary"
                                  name={`pilihan_${outerIndex}_${innerIndex}_${index4}`} // Adjusted name to include outerIndex, innerIndex, and index4
                                  onClick={() => {
                                    if (kondisiSoal) {
                                      handleRadioChangePetunjuk(
                                        item.id_msoal,
                                        item.mt_idsubtest,
                                        item.no_soal,
                                        item.soal,
                                        item3.id_soal_jawaban,
                                        item3.pilihan,
                                        item4,
                                        item3.id_aspekat,
                                        JSON.parse(item.skala),
                                        index4,
                                        item3.id_indikator
                                      )
                                    } else {
                                      handleRadioChange(
                                        item.id_msoal,
                                        item.mt_idsubtest,
                                        item.no_soal,
                                        item.soal,
                                        item3.id_soal_jawaban,
                                        item3.pilihan,
                                        item4,
                                        item3.id_aspekat,
                                        JSON.parse(item.skala),
                                        index4,
                                        item3.id_indikator
                                      )
                                    }
                                  }}
                                  checked={
                                    kondisiSoal
                                      ? selectedOptionspetunjuk.some(
                                        option =>
                                          option.id_msoal ===
                                          item.id_msoal &&
                                          option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                          option.index === index4
                                      )
                                      : selectedOptions.some(
                                        option =>
                                          option.id_msoal ===
                                          item.id_msoal &&
                                          option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                          option.index === index4
                                      )
                                  }
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            ))
          : filteredData.slice(startIndex, endIndex).map((item, outerIndex) => (
            <Col xl={12} key={outerIndex}>
              <Card className="shadow shadow-lg d-flex flex-column">
                <CardHeader className="d-flex border-bottom border-4 bg-white">
                  <p
                    style={customLayout != null ? customLayoutSoal : null}
                    className="card-title"
                    dangerouslySetInnerHTML={{
                      __html: item.soal,
                    }}
                  ></p>
                </CardHeader>
                <CardBody>
                  <table className="table table-striped">
                    <thead>
                      <tr className="text-start fw-bold">
                        <th></th>
                        {JSON.parse(item.skala).map((item2, index2) => (
                          <th key={index2}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item2,
                              }}
                            ></div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {item.pilihan_jawaban.map((item3, innerIndex) => (
                        <tr className="text-start" key={innerIndex}>
                          <td>
                            <div
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              dangerouslySetInnerHTML={{
                                __html: item3.pilihan,
                              }}
                            ></div>
                          </td>
                          {JSON.parse(item3.value).map((item4, index4) => (
                            <td key={index4}>
                              <input
                                type="radio"
                                className="form-check-input border border-2 border-primary"
                                name={`pilihan_${outerIndex}_${innerIndex}_${index4}`} // Adjusted name to include outerIndex, innerIndex, and index4
                                onClick={() => {
                                  if (kondisiSoal) {
                                    handleRadioChangePetunjuk(
                                      item.id_msoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item.soal,
                                      item3.id_soal_jawaban,
                                      item3.pilihan,
                                      item4,
                                      item3.id_aspekat,
                                      JSON.parse(item.skala),
                                      index4,
                                      item3.id_indikator
                                    )
                                  } else {
                                    handleRadioChange(
                                      item.id_msoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item3.id_soal_jawaban,
                                      item4,
                                      item3.id_aspekat,
                                      JSON.parse(item.skala),
                                      index4,
                                      item3.id_indikator
                                    )
                                  }
                                }}
                                checked={
                                  kondisiSoal
                                    ? selectedOptionspetunjuk.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                        item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                                    : selectedOptions.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                        item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          ))}
        <Row>
          <Col lg="12">
            <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
              <PaginationItem
                disabled={page === 1}
                style={{
                  margin: "0 5px",
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PaginationLink
                  className="fs-5"
                  style={{
                    borderRadius: "4px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  previous
                  onClick={e => {
                    e.preventDefault()
                    prevPage(page)
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      transform: "rotate(180deg)",
                    }}
                  >
                    &#10140;
                  </span>{" "}
                  Sebelumnya
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                disabled={page === totalPage}
                style={{
                  margin: "0 5px",
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PaginationLink
                  className="fs-5"
                  style={{
                    borderRadius: "4px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  next
                  onClick={e => {
                    e.preventDefault()
                    nextPage(page)
                  }}
                >
                  Selanjutnya &#10140;
                </PaginationLink>
              </PaginationItem>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="text-end">
              {kondisiSoal && soalPetunjuk.length > 0 && page === totalPage ? (
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => handleSaveJawabanPetunjuk()}
                >
                  Mulai Kerjakan
                </button>
              ) : (
                page === totalPage && (
                  <Button color="primary" onClick={handleSaveJawaban}>
                    Save Jawaban
                  </Button>
                )
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default matrix_ca
